package tta.destinigo.talktoastro.feature_common.persentation.more_tab.component

sealed class MoreScreenEvent {
    data object onClickWalletBlance: MoreScreenEvent()
    data object onPricayPolicyClick: MoreScreenEvent()
    data object onTermAndConditionClick: MoreScreenEvent()
    data object onClickEditPrifile: MoreScreenEvent()
    data object onBackButtonClick: MoreScreenEvent()
    data object onSignOutButtonClick: MoreScreenEvent()
    data object onTransactionHistoryScreenClick: MoreScreenEvent()
    data object onWalletButtonClick: MoreScreenEvent()
    data object onChatHistoryClick: MoreScreenEvent()
}
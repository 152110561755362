package tta.destinigo.talktoastro.core.extenstion

import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.ui.Modifier
import tta.destinigo.talktoastro.core.shared.formatDecimal
import kotlin.text.trimEnd

fun WindowWidthSizeClass.getMaxInputAbleWidthModifier(): Modifier {
    return when (this) {
        WindowWidthSizeClass.Compact -> Modifier.fillMaxWidth()
        WindowWidthSizeClass.Medium -> Modifier.fillMaxWidth(0.6f)
        else -> Modifier.fillMaxWidth(0.45f)
    }
}

fun Double.toFormattedString(): String {
    return if (this % 1.0 == 0.0) {
        this.toInt().toString()
    } else {
        val decimalPart = (this * 100).toInt() % 100
        if (decimalPart == 0) {
            this.toInt().toString()
        } else {
            formatDecimal(this, "#.##").trimEnd('0').trimEnd('.')
        }
    }
}

expect fun openWhatsApp(phoneNumber: String)

package tta.destinigo.talktoastro.feature_expert_consultation.data.response

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Data(
    @SerialName("consultants") val consultants: Consultants,
    @SerialName("currency") val currency: String, // International
    @SerialName("free_call_availability") val freeCallAvailability: Int, // 1
    @SerialName("TotalExperts") val totalExperts: Int // 1
)

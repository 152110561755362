package tta.destinigo.talktoastro.feature_common.persentation.more_tab.chat_history.widget

import androidx.compose.foundation.Image
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.AccessTime
import androidx.compose.material.icons.filled.Chat
import androidx.compose.material.icons.filled.Delete
import androidx.compose.material.icons.filled.Feedback
import androidx.compose.material3.Button
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.Card
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.Icon
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import tta.destinigo.talktoastro.core.persentation.ExpertProfileIcon
import tta.destinigo.talktoastro.core.theme.Scale
import tta.destinigo.talktoastro.core.theme.primaryColor
import tta.destinigo.talktoastro.resources.Res
import tta.destinigo.talktoastro.resources.profile_icon_avtar
import org.jetbrains.compose.resources.painterResource

@Composable
fun ChatHistoryItem(
    expert_id:String,
    chat_id:String,
    name: String,
    image: String,
    expertSkills: String,
    dateTime: String,
    duration: String,
    onAddFeedbackClick: (String,String,String) -> Unit,
    onChatAgainClick: (String,String,String,String,String,String) -> Unit,
    onChatDeleteClick: (String) -> Unit,
    onChatCardClick: (String,String,String,String) -> Unit
) {
    Card(
        modifier = Modifier.clickable { onChatCardClick.invoke(chat_id,name,image,duration) }
            .fillMaxWidth(),
            //.padding(8.dp),
        elevation = CardDefaults.cardElevation(Scale.ELEVATION_SMALL),
        shape = RoundedCornerShape(Scale.CORNER_MEDIUM),
        colors = CardDefaults.cardColors(Color.White)
    ) {
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .padding(16.dp),
            verticalArrangement = Arrangement.SpaceBetween
        ) {
            Row(
                verticalAlignment = Alignment.CenterVertically
            ) {
                // Profile Image

                if (image.isEmpty()){
                    Image(
                        painter = painterResource(Res.drawable.profile_icon_avtar),
                        contentDescription = "Profile Picture",
                        modifier = Modifier
                            .size(64.dp)
                            .clip(CircleShape),
                        contentScale = ContentScale.Crop
                    )
//                    AsyncImage(
//                        model = image,
//                        contentDescription = "expert image",
//                        modifier = Modifier
//                            .size(85.dp)
//                            .clip(CircleShape),
//                        contentScale = ContentScale.Crop
//                    )
                }else{
                    ExpertProfileIcon(profileImageUrl = image, modifier = Modifier.size(60.dp))

                }
                Spacer(modifier = Modifier.width(16.dp))

                // Name and Date
                Column(modifier = Modifier.weight(1f)) {
                    Text(text = name, fontWeight = FontWeight.Bold, fontSize = 18.sp)
                    Text(text = dateTime, color = Color.Gray, fontSize = 14.sp)
                }

                Spacer(modifier = Modifier.width(16.dp))

                // Call Duration
                Column(horizontalAlignment = Alignment.End) {
                    Icon(Icons.Default.AccessTime, contentDescription = "Duration", tint = Color.Gray)
                    Text(text = duration, color = Color.Gray, fontSize = 12.sp)
                }
            }

            Spacer(modifier = Modifier.height(8.dp))

            // Actions: Add Feedback and Chat Again
            Row(
                modifier = Modifier.fillMaxWidth(),
                horizontalArrangement = Arrangement.SpaceBetween,
                verticalAlignment = Alignment.CenterVertically
            ) {
                // Delete chat button
                Button(
                    onClick = { onChatDeleteClick(chat_id) },
                    shape = RoundedCornerShape(Scale.CORNER_EXTRA_SMALL),
                    colors = ButtonDefaults.buttonColors(primaryColor.copy(alpha = 0.16f)),
                ){
                    Icon(Icons.Default.Delete, contentDescription = "Delete", tint = Color.Black)
                }
                Spacer(modifier = Modifier.width(8.dp))
                // Add Feedback Button
//                Button(
//                    onClick = onAddFeedbackClick,
//                    shape = RoundedCornerShape(Scale.CORNER_EXTRA_SMALL),
//                    colors = ButtonDefaults.buttonColors(Color.LightGray),
//                    modifier = Modifier.weight(1f)
//                ) {
//                    Row(
//                        modifier = Modifier.fillMaxWidth(),
//                        horizontalArrangement = Arrangement.Center
//                    ) {
//                        Icon(
//                            Icons.Default.Feedback,
//                            contentDescription = "Add Feedback",
//                            tint = Color.Black,
//                            modifier = Modifier.size(25.dp) // Set the size of the icon
//                        )
//                        Spacer(modifier = Modifier.width(4.dp)) // Minimal space after the icon
//                        Text(
//                            text = "Feedback",
//                            color = Color.Black,
//                            fontSize = 15.sp,
//                            maxLines = 1,
//                            overflow = TextOverflow.Ellipsis // Handle overflow
//                        )
//                    }
//                }
                Button(
                    onClick = { onAddFeedbackClick.invoke(expert_id,name,image) },
                    shape = RoundedCornerShape(Scale.CORNER_EXTRA_SMALL),
                    colors = ButtonDefaults.buttonColors(primaryColor.copy(alpha = 0.14f)),
                    modifier = Modifier.weight(1f)
                ) {
                    Row(
                        modifier = Modifier.fillMaxWidth(),
                        horizontalArrangement = Arrangement.Center,
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        Icon(
                            imageVector = Icons.Default.Feedback,
                            contentDescription = "Add Feedback",
                            tint = Color.Black,
                            modifier = Modifier.size(25.dp) // Set the size of the icon without weight
                        )
                        Spacer(modifier = Modifier.width(4.dp)) // Space between icon and text
                        Text(
                            text = "Feedback",
                            color = Color.Black,
                            fontSize = 15.sp,
                            maxLines = 1,
                            overflow = TextOverflow.Ellipsis // Handle overflow
                        )
                    }
                }

                Spacer(modifier = Modifier.width(8.dp))

                // Chat Again Button
                Button(
                    onClick = {onChatAgainClick.invoke(expert_id,name,expertSkills,image,
                        null.toString(),"false")},
                    shape = RoundedCornerShape(Scale.CORNER_EXTRA_SMALL),
                    colors = ButtonDefaults.buttonColors(primaryColor.copy(alpha = 0.11f)),
                    modifier = Modifier.weight(1f).padding(0.dp)
                ) {
                    Row(
                        modifier = Modifier.fillMaxWidth(),
                        horizontalArrangement = Arrangement.Center
                    ) {
                        Icon(
                            Icons.Default.Chat,
                            contentDescription = "Chat Again",
                            tint = primaryColor,
                            modifier = Modifier.padding(0.dp)
                        )
                        Spacer(modifier = Modifier.width(4.dp))
                        Text(
                            text = "Chat Again",
                            color = primaryColor,
                            fontSize = 15.sp,
                            maxLines = 1,
                            overflow = TextOverflow.Ellipsis // Handle overflow
                        )
                    }
                }
            }
        }
    }
}

package tta.destinigo.talktoastro.feature_winyway_wallet.persentation.recharge_getway

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.runtime.Composable

import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier

@Composable
fun PaymentGatewayPage(
    paymentGatewayComponent: PaymentGatewayComponent
) {

    Box(
        modifier = Modifier.fillMaxSize()
    ) {

        CircularProgressIndicator(
            modifier = Modifier.align(Alignment.Center)
        )
    }
}
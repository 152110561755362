package tta.destinigo.talktoastro.feature_custom_order

import co.touchlab.kermit.Logger
import co.touchlab.kermit.Message
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.essenty.lifecycle.doOnDestroy
import com.arkivanov.essenty.lifecycle.doOnStart
import kotlinx.coroutines.CoroutineExceptionHandler
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.cancel
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.getScopeName
import org.koin.core.component.inject
import tta.destinigo.talktoastro.core.remote.Resources
import tta.destinigo.talktoastro.core.theme.isLogin
import tta.destinigo.talktoastro.feature_chat_consultation.persentation.chat_bottom_sheet_info.ChatInfoBottomSheetState
import tta.destinigo.talktoastro.feature_common.persentation.more_tab.chat_history.data.response.ChatHistoryListData
import tta.destinigo.talktoastro.feature_custom_order.data.request.CustomOrderListRequest
import tta.destinigo.talktoastro.feature_custom_order.data.response.DataX
import tta.destinigo.talktoastro.feature_custom_order.domain.CustomOrderListRepo

//class CustomOrderComponent(
//    context: ComponentContext
//): ComponentContext by context, KoinComponent {
//
//    private val coroutineScope = CoroutineScope(SupervisorJob() + Dispatchers.Main)
//    private val api: CustomOrderListRepo by inject()
//    private val _state =
//        MutableStateFlow<CustomOrderListState>(CustomOrderListState.Loading(false))
//    val state get() = _state.asStateFlow()
//    init {
//        lifecycle.doOnStart {
//            Logger.d { "Custom Order Screen Component onStart" }
//            coroutineScope.launch {
//                getCustomOrderList("1")
//            }
//        }
//
//        lifecycle.doOnDestroy {
//            Logger.d { "Custom Order Screen Component onDestroy" }
//            coroutineScope.cancel() // Cancel all coroutines when the component is destroyed
//        }
//    }
//
//
//    private suspend fun getCustomOrderList(pageNumber:String){
//        api.getCustomOrderInfo(CustomOrderListRequest(page = pageNumber)).collect {
//            when (it) {
//                is Resources.Error -> onError(it.msg ?: "Unexpected Error")
//                is Resources.Loading -> onLoading(it.isLoading)
//                is Resources.Success -> {
//                    Logger.d { "getCustomOrderList ${it.data}" }
//                    Logger.d { "getCustomOrderList ${it.msg}" }
//                    it.data.let { data->
//                        data?.let { it1 -> onSuccess(it1.data) }
//                    }
//                }
//            }
//        }
//
//    }
//
//    private suspend fun onError(message: String) = coroutineScope.launch{
//        _state.emit(CustomOrderListState.Error(message))
//    }
//    private suspend fun onLoading(isLoading:Boolean) = coroutineScope.launch{
//        _state.emit(CustomOrderListState.Loading(isLoading))
//    }
//    private suspend fun onSuccess(data: List<DataX>) = coroutineScope.launch{
//        Logger.d { "On Sucess function" }
//        _state.emit(CustomOrderListState.CustomOrderListSuccess(data))
//    }
//}


class CustomOrderComponent(
    context: ComponentContext
): ComponentContext by context, KoinComponent {

    private val exceptionHandler = CoroutineExceptionHandler { _, throwable ->
        Logger.e { "Coroutine Exception: ${throwable.message}" }
        throwable.printStackTrace()  // Ensure you print the full stack trace for debugging
    }

    private val coroutineScope = CoroutineScope(SupervisorJob() + Dispatchers.Main + exceptionHandler)
    private val api: CustomOrderListRepo by inject()

    private val _state = MutableStateFlow<List<DataX>>(emptyList()) // Changed nullable type to non-nullable
    val state: StateFlow<List<DataX>> = _state
   // private val _state = MutableStateFlow<CustomOrderListState>(CustomOrderListState.Loading(false))
    private var currentPage = 1
//    val state get() = _state.asStateFlow()
    private var isFetchingNextPage = false

    init {
        lifecycle.doOnStart {
            Logger.d { "Custom Order Screen Component onStart" }
            coroutineScope.launch {
                getCustomOrderList(currentPage.toString())
            }
        }

        lifecycle.doOnDestroy {
            Logger.d { "Custom Order Screen Component onDestroy" }
            coroutineScope.cancel()
        }
    }

    private suspend fun getCustomOrderList(pageNumber: String) {
        if (isFetchingNextPage) return  // Prevent multiple requests for the same page
        isFetchingNextPage = true

        api.getCustomOrderInfo(CustomOrderListRequest(page = pageNumber)).collect {
            when (it) {
                is Resources.Error -> onError(it.msg ?: "Unexpected Error")
                is Resources.Loading -> onLoading(it.isLoading)
                is Resources.Success -> {
                    val currentData = (_state.value as? CustomOrderListState.CustomOrderListSuccess)?.data ?: emptyList()
                    _state.emit(currentData + (it.data?.data ?: emptyList()))
                    //_state.emit()
//                    Logger.d { "getCustomOrderList ${it.data}" }
//                    Logger.d { "getCustomOrderList ${it.msg}" }
//                    it.data?.data?.let { it1 -> onSuccess(data = it1) }
////                    it.data?.let { data ->
////                        onSuccess(data.data)
////                    }
                }
            }
        }
        isFetchingNextPage = false
    }

    private suspend fun onError(message: String) = coroutineScope.launch {
       // _state.emit(CustomOrderListState.Error(message))
        isFetchingNextPage = false
    }

    private suspend fun onLoading(isLoading: Boolean) = coroutineScope.launch {
       // _state.emit(CustomOrderListState.Loading(isLoading))
    }

    private suspend fun onSuccess(data: List<DataX>) = coroutineScope.launch {
        Logger.d { "On Success function" }
        Logger.d { "On Success function $data" }

        currentPage++  // Increment page number for the next load
    }

    fun loadNextPage() = coroutineScope.launch {
        ++currentPage
        getCustomOrderList(currentPage.toString())
    }
}

package tta.destinigo.talktoastro.feature_authentication.di

import tta.destinigo.talktoastro.feature_authentication.data.RegisterApiService
import tta.destinigo.talktoastro.user_feature_authentication.data.AuthApiService
import tta.destinigo.talktoastro.user_feature_authentication.domain.OtpValidationUseCases
import tta.destinigo.talktoastro.user_feature_authentication.domain.RegistrationUserUseCases
import tta.destinigo.talktoastro.feature_expert_consultation.data.remote.ExpertApi
import org.koin.dsl.module

val authorizationModule = module {
    single { AuthApiService() }
    single { OtpValidationUseCases(get()) }
    single { RegistrationUserUseCases(get()) }
    single { ExpertApi() }
    single { RegisterApiService() }
}
package tta.destinigo.talktoastro.feature_landing_page.persentation.widget

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.ArrowBack
import androidx.compose.material.icons.filled.ArrowForward
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import tta.destinigo.talktoastro.core.theme.background_color
import tta.destinigo.talktoastro.feature_landing_page.data.response.Testimonial
import tta.destinigo.talktoastro.resources.Res
import tta.destinigo.talktoastro.resources.ic_person
import org.jetbrains.compose.resources.painterResource


//@OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
//@Composable
//fun TestimonialCarousel(testimonials: List<Testimonial>) {
//    var currentIndex by remember { mutableStateOf(0) }
//    val windowSizeClass = calculateWindowSizeClass().widthSizeClass
//    Box(
//        contentAlignment = Alignment.Center,
//        modifier = Modifier
//            .fillMaxWidth()
//            .height(if (windowSizeClass == WindowWidthSizeClass.Compact) 250.dp else 300.dp)
//            .padding(16.dp)
//            .background(MaterialTheme.colorScheme.background, RoundedCornerShape(16.dp))
//            .shadow(
//                elevation = 4.dp,
//                ambientColor = Color.Gray.copy(alpha = 0.4f), // Customize the ambient shadow color
//                spotColor = Color.Black.copy(alpha = 0.2f), // Customize the spot shadow color
//                shape = RoundedCornerShape(16.dp) // Ensure shadow matches the shape
//            )
//    ) {
//        // Main row holding the arrows and the testimonial content
//        Row(
//            horizontalArrangement = Arrangement.SpaceBetween,
//            verticalAlignment = Alignment.CenterVertically,
//            modifier = Modifier.fillMaxSize()
//        ) {
//            // Left arrow (Previous)
//            IconButton(
//                onClick = {
//                    if (currentIndex > 0) {
//                        currentIndex--
//                    }
//                },
//                enabled = currentIndex > 0,
//                modifier = Modifier
//                    .padding(8.dp)
//                    .background(MaterialTheme.colorScheme.primary.copy(alpha = 0.1f), CircleShape)
//                    .border(1.dp, MaterialTheme.colorScheme.primary, CircleShape)
//            ) {
//                Icon(
//                    imageVector = Icons.Default.ArrowBack,
//                    contentDescription = "Previous",
//                    tint = if (currentIndex > 0) MaterialTheme.colorScheme.primary else Color.Gray,
//                    modifier = Modifier.size(24.dp) // Adjust icon size
//                )
//            }
//
//            // Testimonial content in the middle
//            Column(
//                horizontalAlignment = Alignment.CenterHorizontally,
//                verticalArrangement = Arrangement.Center,
//                modifier = Modifier
//                    .fillMaxWidth()
//                    .weight(1f)
//                    .padding(horizontal = 16.dp)
//            ) {
//                // Scrollable area for the content
//                val scrollState = rememberScrollState()
//
//
//                Column(
//                    modifier = Modifier
//                        .fillMaxWidth()
//                        .verticalScroll(scrollState),
//                    horizontalAlignment = Alignment.CenterHorizontally
//                ) {
//                    // Testimonial message
//                    Text(
//                        text = testimonials[currentIndex].message,
//                        style = MaterialTheme.typography.titleMedium.copy(
//                            // Uncomment and use your custom font
//                            // fontFamily = FontFamily(Res.font.roboto_black_italic),
//                            // Add text shadow for better readability
//                        ),
//                        fontSize = if (windowSizeClass == WindowWidthSizeClass.Compact) 14.sp else 16.sp,
//                        modifier = Modifier
//                            .align(Alignment.CenterHorizontally)
//                            .padding(horizontal = 16.dp), // Add horizontal padding
//                            //.background(Color.Gray.copy(alpha = 0.1f), shape = RoundedCornerShape(8.dp)), // Add background with rounded corners
//                        textAlign = TextAlign.Center,
//                        maxLines = if (windowSizeClass == WindowWidthSizeClass.Compact) 5 else 3,
//                        overflow = TextOverflow.Ellipsis,
//                        color = Color.DarkGray // Use a color that complements your theme
//                    )
//
//
//                    Spacer(modifier = Modifier.height(10.dp))
//
//                    // Author and location
//                    Text(
//                        text = testimonials[currentIndex].author,
//                        style = MaterialTheme.typography.titleMedium,
//                        color = MaterialTheme.colorScheme.onBackground.copy(alpha = 0.7f),
//                        modifier = Modifier.align(Alignment.CenterHorizontally),
//                        textAlign = TextAlign.Center,
//                        fontWeight = FontWeight.Bold
//                    )
//                }
//            }
//
//            // Right arrow (Next)
//            IconButton(
//                onClick = {
//                    if (currentIndex < testimonials.size - 1) {
//                        currentIndex++
//                    }
//                },
//                enabled = currentIndex < testimonials.size - 1,
//                modifier = Modifier
//                    .padding(8.dp)
//                    .background(MaterialTheme.colorScheme.primary.copy(alpha = 0.1f), CircleShape)
//                    .border(1.dp, MaterialTheme.colorScheme.primary, CircleShape)
//            ) {
//                Icon(
//                    imageVector = Icons.Default.ArrowForward,
//                    contentDescription = "Next",
//                    tint = if (currentIndex < testimonials.size - 1) MaterialTheme.colorScheme.primary else Color.Gray,
//                    modifier = Modifier.size(24.dp) // Adjust icon size
//                )
//            }
//        }
//    }
//}


@OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun TestimonialCarousel(testimonials: List<Testimonial>) {
    var currentIndex by remember { mutableStateOf(0) }
    val windowSizeClass = calculateWindowSizeClass().widthSizeClass

    Column(
        horizontalAlignment = Alignment.CenterHorizontally,
        modifier = Modifier
            .fillMaxWidth()
            .background(background_color)
            .padding(16.dp)
    ) {
        // Title text: "Our Clients Say"
        Text(
            text = "Our Student Says",
            style = MaterialTheme.typography.headlineSmall,
            fontWeight = FontWeight.Bold,
            modifier = Modifier.padding(bottom = 16.dp)
        )

        // Box to contain the content and arrows
        Box(
            contentAlignment = Alignment.Center,
            modifier = Modifier
                .fillMaxWidth()
                .height(if (windowSizeClass == WindowWidthSizeClass.Compact) 350.dp else 250.dp)
               // .background(MaterialTheme.colorScheme.surface, RoundedCornerShape())
                .padding(if (windowSizeClass == WindowWidthSizeClass.Compact) 10.dp else 16.dp)
               // .shadow(4.dp, RoundedCornerShape(16.dp))
        ) {
            // Row to hold the arrows and testimonial content
            Row(
                horizontalArrangement = Arrangement.SpaceBetween,
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier.fillMaxSize()
            ) {
                // Left arrow (Previous)
                IconButton(
                    onClick = {
                        if (currentIndex > 0) {
                            currentIndex--
                        }
                    },
                    enabled = currentIndex > 0,
                    modifier = Modifier
                        .padding(8.dp)
                        .background(
                            MaterialTheme.colorScheme.primary.copy(alpha = 0.1f),
                            CircleShape
                        )
                        .border(1.dp, MaterialTheme.colorScheme.primary, CircleShape)
                ) {
                    Icon(
                        imageVector = Icons.Default.ArrowBack,
                        contentDescription = "Previous",
                        tint = if (currentIndex > 0) MaterialTheme.colorScheme.primary else Color.Gray,
                        modifier = Modifier.size(24.dp)
                    )
                }

                // Testimonial content in the middle
                Column(
                    horizontalAlignment = Alignment.CenterHorizontally,
                    verticalArrangement = Arrangement.Center,
                    modifier = Modifier
                        .fillMaxWidth()
                        .weight(1f)
                        .padding(horizontal = 16.dp)
                ) {
                    // Circular Image
                    Image(
                        painter = painterResource(Res.drawable.ic_person),
                        contentDescription = "Client Image",
                        modifier = Modifier
                            .size(100.dp)
                            .clip(CircleShape)
                            //.border(4.dp, MaterialTheme.colorScheme.primary, CircleShape)
                            .padding(4.dp)
                    )

                    Spacer(modifier = Modifier.height(10.dp))

                    // Testimonial quote
                    Text(
                        text = "\"${testimonials[currentIndex].message}\"",
                        style = MaterialTheme.typography.bodyLarge,
                        textAlign = TextAlign.Center,
                        fontSize = if (windowSizeClass == WindowWidthSizeClass.Compact) 13.sp else 23.sp ,
                        color = MaterialTheme.colorScheme.onBackground,
                        modifier = Modifier
                            //.padding(horizontal = 16.dp)
                            .fillMaxWidth(),
                            //.fillMaxHeight(),
                        maxLines = 6,
                        overflow = TextOverflow.Ellipsis,
                    )

                    Spacer(modifier = Modifier.height(10.dp))

                    // Author name
                    Text(
                        text = testimonials[currentIndex].author,
                        fontSize = if (windowSizeClass == WindowWidthSizeClass.Compact) 12.sp else 20.sp,
                        style = MaterialTheme.typography.titleMedium.copy(fontWeight = FontWeight.Bold),
                        color = MaterialTheme.colorScheme.primary,
                        textAlign = TextAlign.Center
                    )
                }

                // Right arrow (Next)
                IconButton(
                    onClick = {
                        if (currentIndex < testimonials.size - 1) {
                            currentIndex++
                        }
                    },
                    enabled = currentIndex < testimonials.size - 1,
                    modifier = Modifier
                        .padding(8.dp)
                        .background(
                            MaterialTheme.colorScheme.primary.copy(alpha = 0.1f),
                            CircleShape
                        )
                        .border(1.dp, MaterialTheme.colorScheme.primary, CircleShape)
                ) {
                    Icon(
                        imageVector = Icons.Default.ArrowForward,
                        contentDescription = "Next",
                        tint = if (currentIndex < testimonials.size - 1) MaterialTheme.colorScheme.primary else Color.Gray,
                        modifier = Modifier.size(24.dp)
                    )
                }
            }
        }

        Spacer(modifier = Modifier.height(10.dp))

        // Dots indicator
        Row(
            horizontalArrangement = Arrangement.Center,
            modifier = Modifier.fillMaxWidth()
        ) {
            repeat(testimonials.size) { index ->
                Box(
                    modifier = Modifier
                        .size(8.dp)
                        .clip(CircleShape)
                        .background(if (index == currentIndex) MaterialTheme.colorScheme.primary else Color.Gray)
                        .padding(horizontal = 4.dp)
                )
            }
        }
    }
}

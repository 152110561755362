package tta.destinigo.talktoastro.feature_common.persentation.more_tab.chat_history

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.lazy.rememberLazyListState
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.Text
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.runtime.snapshotFlow
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import co.touchlab.kermit.Logger
import tta.destinigo.talktoastro.core.persentation.CommonTopAppBar
import tta.destinigo.talktoastro.core.theme.Scale.WINDOW_WIDTH_SIZE_COMPACT
import tta.destinigo.talktoastro.core.theme.Scale.WINDOW_WIDTH_SIZE_ELSE
import tta.destinigo.talktoastro.core.theme.Scale.WINDOW_WIDTH_SIZE_EXPEND
import tta.destinigo.talktoastro.core.theme.Scale.WINDOW_WIDTH_SIZE_MEDIUM
import tta.destinigo.talktoastro.core.theme.background_color
import tta.destinigo.talktoastro.feature_common.data.request.RequestConsultant
import tta.destinigo.talktoastro.feature_common.persentation.more_tab.chat_history.widget.ChatHistoryItem
import tta.destinigo.talktoastro.feature_common.persentation.more_tab.chat_history.widget.DeleteChatConfirmationDialog
import tta.destinigo.talktoastro.feature_common.persentation.more_tab.chat_history.widget.UserFeedback
import tta.destinigo.talktoastro.feature_common.persentation.more_tab.component.ChatHistoryComponent
import tta.destinigo.talktoastro.feature_common.persentation.more_tab.transaction_history.persentation.convertToIndianTime
import tta.destinigo.talktoastro.feature_expert_consultation.presentation.expert_detail_page.ExpertDetailsPageComponent

//@OptIn(ExperimentalMaterial3Api::class, ExperimentalMaterial3WindowSizeClassApi::class)
//@Composable
//fun ChatHistoryScreen(component: ChatHistoryComponent,expertDetailsPageComponent: ExpertDetailsPageComponent) {
//    val windowSizeClass = calculateWindowSizeClass().widthSizeClass
//    val widthModifier = when (windowSizeClass) {
//        WindowWidthSizeClass.Compact -> WINDOW_WIDTH_SIZE_COMPACT
//        WindowWidthSizeClass.Medium -> WINDOW_WIDTH_SIZE_MEDIUM
//        WindowWidthSizeClass.Expanded -> WINDOW_WIDTH_SIZE_EXPEND
//        else -> WINDOW_WIDTH_SIZE_ELSE
//    }
//    var isChatClickSelected by remember { mutableStateOf<RequestConsultant?>(null) }
//    var onClickDeleteChatHistoryCard by remember { mutableStateOf(false) }
//    var selectedChatId by remember { mutableStateOf<String?>(null) }
//    var selectedChatCard by remember { mutableStateOf(Triple<String?, String?, String?>(null, null, null)) }
//    var onAddFeedBackClick by remember { mutableStateOf(false) }
//    val listState = rememberLazyListState()
//
//    val chatHistoryList by component.state.collectAsState()
//
////    LaunchedEffect(listState) {
////        snapshotFlow { listState.layoutInfo.visibleItemsInfo.lastOrNull()?.index }
////            .collect { lastVisibleItemIndex ->
////                if (lastVisibleItemIndex == listState.layoutInfo.totalItemsCount - 1) {
////                    component.loadMoreChatHistory()
////                }
////            }
////    }
//
//    LaunchedEffect(listState) {
//        snapshotFlow {
//            listState.layoutInfo.visibleItemsInfo.lastOrNull()?.index
//        }.collect { lastVisibleItemIndex ->
//            // Check if the last visible item is close to the end of the list
//            if (lastVisibleItemIndex != null && lastVisibleItemIndex >= (chatHistoryList[0]?.chatList?.size?.minus(
//                    3
//                ) ?: 0)
//            ) {
//                component.loadMoreChatHistory()
//            }
//        }
//    }
//
//
//    if(onClickDeleteChatHistoryCard){
//        Logger.d { "chat id $selectedChatId" }
//        DeleteChatConfirmationDialog(onDeleteChat = {
//            if(selectedChatId != null){
//                component.deleteChatMessage(selectedChatId!!)
//                onClickDeleteChatHistoryCard = false
//            }
//        }, onNotNow = {onClickDeleteChatHistoryCard = false},onDismissRequest = {onClickDeleteChatHistoryCard = false})
//    }
//
//    if(onAddFeedBackClick){
//        UserFeedback(expert_id =  selectedChatCard.first.toString(),profileIcon = selectedChatCard.third.toString(), name = selectedChatCard.second.toString(), onRateNow = {}, onSkip = { onAddFeedBackClick = false}, component =  component)
//    }
//
//    Box(
//        modifier = Modifier
//            .fillMaxSize()
//            .background(background_color),
//        contentAlignment = Alignment.Center
//    ) {
//        Column(
//            modifier = widthModifier
//                .fillMaxSize()
//                .padding(16.dp, top = 0.dp)
//        ) {
//            CommonTopAppBar(
//                title = "Chat History",
//                modifier = Modifier,
//                onBackClick = { component.onBackButtonClick() }
//            )
//            Spacer(modifier = Modifier.height(16.dp))
//            if (chatHistoryList.isNotEmpty()) {
//                LazyColumn(
//                    state = listState,
//                    modifier = Modifier.fillMaxSize()
//                ) {
//                    chatHistoryList.firstOrNull()?.chatList?.size.let {
//                        items(it) { index ->
//                            val chatItem = chatHistoryList[0].chatList?.get(index)
//                            ChatHistoryItem(
//                                expert_id = chatItem?.expertId.toString(),
//                                chat_id = chatItem?.chatId.toString(),
//                                name = chatItem?.expertName.toString(),
//                                dateTime = convertToIndianTime(chatItem?.dateTime.toString()),
//                                duration = "${chatItem?.duration} MIN",
//                                image = chatItem?.expertProfileImage.toString(),
//                                expertSkills = chatItem?.expertSkills.toString(),
//                                onAddFeedbackClick = { expert_id, name, image ->
//                                    selectedChatCard = selectedChatCard.copy(first = expert_id, second = name, third = image)
//                                    onAddFeedBackClick = true
//                                },
//                                onChatAgainClick = {expertId, expertName, expertSkills, expertImage, categoryId, chatType ->
//                                    Logger.d { "$expertId, $expertName, $expertImage, $expertSkills" }
//                                },
//                                onChatDeleteClick = { chat_id ->
//                                    selectedChatId = chat_id
//                                    onClickDeleteChatHistoryCard = true
//                                },
//                                onChatCardClick = { chat_id, name, image, duration ->
//                                    component.onChatCardClick(chat_id,name,image,duration)
//                                }
//                            )
//                            Spacer(modifier = Modifier.height(8.dp))
//                        }
//                    }
//                    }
//                    //chatHistoryList[0]?.chatList?.size?.let {
////                        items(it) { index ->
////                            val chatItem = chatHistoryList[0]?.chatList?.get(index)
////                            ChatHistoryItem(
////                                expert_id = chatItem?.expertId.toString(),
////                                chat_id = chatItem?.chatId.toString(),
////                                name = chatItem?.expertName.toString(),
////                                dateTime = convertToIndianTime(chatItem?.dateTime.toString()),
////                                duration = "${chatItem?.duration} MIN",
////                                image = chatItem?.expertProfileImage.toString(),
////                                expertSkills = chatItem?.expertSkills.toString(),
////                                onAddFeedbackClick = { expert_id, name, image ->
////                                    selectedChatCard = selectedChatCard.copy(first = expert_id, second = name, third = image)
////                                    onAddFeedBackClick = true
////                                                     },
////                                onChatAgainClick = {expertId, expertName, expertSkills, expertImage, categoryId, chatType ->
////                                    Logger.d { "$expertId, $expertName, $expertImage, $expertSkills" }
////                                },
////                                onChatDeleteClick = { chat_id ->
////                                    selectedChatId = chat_id
////                                    onClickDeleteChatHistoryCard = true
////                                },
////                                onChatCardClick = { chat_id, name, image, duration ->
////                                    component.onChatCardClick(chat_id,name,image,duration)
////                                }
////                            )
////                            Spacer(modifier = Modifier.height(8.dp))
////                        }
//                    }
//                //}
//            } else {
//                Text(
//                    text = "No chat history available.",
//                    modifier = Modifier.align(Alignment.CenterHorizontally),
//                    color = Color.Gray
//                )
//            }
//        }
//    }
//}


@OptIn(ExperimentalMaterial3Api::class, ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun ChatHistoryScreen(component: ChatHistoryComponent, expertDetailsPageComponent: ExpertDetailsPageComponent) {
    val windowSizeClass = calculateWindowSizeClass().widthSizeClass
    val widthModifier = when (windowSizeClass) {
        WindowWidthSizeClass.Compact -> WINDOW_WIDTH_SIZE_COMPACT
        WindowWidthSizeClass.Medium -> WINDOW_WIDTH_SIZE_MEDIUM
        WindowWidthSizeClass.Expanded -> WINDOW_WIDTH_SIZE_EXPEND
        else -> WINDOW_WIDTH_SIZE_ELSE
    }

    var isChatClickSelected by remember { mutableStateOf<RequestConsultant?>(null) }
    var onClickDeleteChatHistoryCard by remember { mutableStateOf(false) }
    var selectedChatId by remember { mutableStateOf<String?>(null) }
    var selectedChatCard by remember { mutableStateOf(Triple<String?, String?, String?>(null, null, null)) }
    var onAddFeedBackClick by remember { mutableStateOf(false) }
    val listState = rememberLazyListState()
    val chatHistoryList by component.state.collectAsState()


    LaunchedEffect(listState) {
        snapshotFlow {
            listState.layoutInfo.visibleItemsInfo.lastOrNull()?.index
        }.collect { lastVisibleItemIndex ->
            // Load more chat history when nearing the end of the list
            if (lastVisibleItemIndex != null && lastVisibleItemIndex >= (chatHistoryList.firstOrNull()?.chatList?.size?.minus(3) ?: 0)) {
                component.loadMoreChatHistory()
            }
        }
    }

    if (onClickDeleteChatHistoryCard) {
        DeleteChatConfirmationDialog(
            onDeleteChat = {
                selectedChatId?.let { component.deleteChatMessage(it) }
                onClickDeleteChatHistoryCard = false
            },
            onNotNow = { onClickDeleteChatHistoryCard = false },
            onDismissRequest = { onClickDeleteChatHistoryCard = false }
        )
    }

    if (onAddFeedBackClick) {
        UserFeedback(
            expert_id = selectedChatCard.first.orEmpty(),
            profileIcon = selectedChatCard.third.orEmpty(),
            name = selectedChatCard.second.orEmpty(),
            onRateNow = {},
            onSkip = { onAddFeedBackClick = false },
            component = component
        )
    }

    Box(
        modifier = Modifier
            .fillMaxSize()
            .background(background_color),
        contentAlignment = Alignment.Center
    ) {
        Column(
            modifier = widthModifier
                .fillMaxSize()
                .padding(16.dp, top = 0.dp)
        ) {
            CommonTopAppBar(
                title = "Chat History",
                modifier = Modifier,
                onBackClick = { component.onBackButtonClick() }
            )
            Spacer(modifier = Modifier.height(16.dp))
            if (chatHistoryList.isNotEmpty() && chatHistoryList.firstOrNull()?.chatList?.isNotEmpty() == true) {
                LazyColumn(
                    state = listState,
                    modifier = Modifier.fillMaxSize()
                ) {
                    items(chatHistoryList.flatMap { it.chatList.orEmpty() }) { chatItem -> // Flatten the list if needed
                        chatItem?.let {
                            ChatHistoryItem(
                                expert_id = it.expertId.toString(),
                                chat_id = it.chatId.toString(),
                                name = it.expertName.toString(),
                                dateTime = convertToIndianTime(it.dateTime.toString()),
                                duration = "${it.duration} MIN",
                                image = it.expertProfileImage.toString(),
                                expertSkills = it.expertSkills.toString(),
                                onAddFeedbackClick = { expert_id, name, image ->
                                    selectedChatCard = selectedChatCard.copy(first = expert_id, second = name, third = image)
                                    onAddFeedBackClick = true
                                },
                                onChatAgainClick = { expertId, expertName, expertSkills, expertImage, categoryId, chatType ->
                                    Logger.d { "$expertId, $expertName, $expertImage, $expertSkills" }
                                },
                                onChatDeleteClick = { chat_id ->
                                    selectedChatId = chat_id
                                    onClickDeleteChatHistoryCard = true
                                },
                                onChatCardClick = { chat_id, name, image, duration ->
                                    component.onChatCardClick(chat_id, name, image, duration)
                                }
                            )
                            Spacer(modifier = Modifier.height(8.dp))
                        }
                    }
                }
            } else {
                Text(
                    text = "No chat history available.",
                    modifier = Modifier.align(Alignment.CenterHorizontally),
                    color = Color.Gray
                )
            }

        }
    }
}

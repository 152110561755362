package tta.destinigo.talktoastro.feature_call_consultation.persentation.voip_call_page

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.VolumeOff
import androidx.compose.material.icons.automirrored.filled.VolumeUp
import androidx.compose.material.icons.filled.CallEnd
import androidx.compose.material.icons.filled.Mic
import androidx.compose.material.icons.filled.MicOff
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import org.jetbrains.compose.resources.painterResource
import tta.destinigo.talktoastro.core.extenstion.getMaxInputAbleWidthModifier
import tta.destinigo.talktoastro.core.persentation.ExpertProfileIcon
import tta.destinigo.talktoastro.core.theme.Scale
import tta.destinigo.talktoastro.resources.Res
import tta.destinigo.talktoastro.resources.icon_logo


@OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun CallConsultationPage(
    component: VoipCallComponent
) {
    val windowSizeClass = calculateWindowSizeClass().widthSizeClass
    val isCompact = windowSizeClass == WindowWidthSizeClass.Compact
    val newtworkState by component.networkStatusState.collectAsState()

    // Background and main layout
    Box(
        modifier = Modifier
            .fillMaxSize()
            .background(MaterialTheme.colorScheme.primary),
        contentAlignment = Alignment.Center
    ) {
        Column(
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.SpaceBetween,
            modifier = Modifier.fillMaxSize()
        ) {
            // Top Section: Network Status, Call Status
            TopBar(
                networkStatus = newtworkState
            )

            // Logo and Expert Information
            ExpertInfoSection(
                isCompact = isCompact,
                component = component
            )

            // Bottom Section: Controls (Mute, Speaker, End Call)
            CallControlSection(
                modifier = windowSizeClass.getMaxInputAbleWidthModifier(),
                onMuteToggle = { component.onMuteToggle(it) },
                onEndCall = { component.onEndCall() },
                onSpeakerToggle = { component.onSpeakerToggle(it) }
            )
        }
    }
}

@Composable
fun TopBar(networkStatus: String) {
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .padding(16.dp),
        horizontalArrangement = Arrangement.SpaceBetween,
        verticalAlignment = Alignment.CenterVertically
    ) {
        Text(text = "Call Consultation", color = Color.White)
        Column(horizontalAlignment = Alignment.CenterHorizontally) {
            Text(text = networkStatus, color = Color.White)
        }
    }
}

@Composable
fun ExpertInfoSection(isCompact: Boolean, component: VoipCallComponent) {

    val status by component.callState.collectAsState()

    Column(
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.Center
    ) {
        Image(
            painter = painterResource(Res.drawable.icon_logo), // Add your logo here
            contentDescription = "Winyway Logo",
            modifier = Modifier.size(if (isCompact) 100.dp else 150.dp)
        )
        Spacer(modifier = Modifier.height(16.dp))
        ExpertProfileIcon(component.expertDetail.expertProfileImageUrl, modifier = Modifier.size(if (isCompact) 100.dp else 150.dp))
        Spacer(modifier = Modifier.height(16.dp))
        Text(
            text = component.expertDetail.expertName,
            style = MaterialTheme.typography.headlineSmall.copy(color = Color.White)
        )
        Text(
            text = status,
            style = MaterialTheme.typography.bodyMedium.copy(color = Color.White)
        )
    }
}

@Composable
fun CallControlSection(
    modifier: Modifier,
    onMuteToggle: (Boolean) -> Unit,
    onEndCall: () -> Unit,
    onSpeakerToggle: (Boolean) -> Unit
) {

    val isSpeakerOn = rememberSaveable { mutableStateOf(true) }
    val isMuted = rememberSaveable { mutableStateOf(false) }

    Row(
        modifier = modifier
            .fillMaxWidth()
            .padding(16.dp)
            .background(shape = RoundedCornerShape(Scale.CORNER_SMALL), color = Color.Black.copy(alpha = 0.2f)),
        horizontalArrangement = Arrangement.SpaceEvenly,
        verticalAlignment = Alignment.CenterVertically
    ) {
        IconButton(
            onClick = {
                onMuteToggle.invoke(isMuted.value)
                isMuted.value =  !isMuted.value
            },
            modifier = Modifier.size(56.dp)
        ) {
            Icon(
                imageVector = if (isMuted.value) Icons.Default.MicOff else Icons.Default.Mic,
                contentDescription = if (isMuted.value) "Unmute" else "Mute",
                tint = Color.White
            )
        }

        IconButton(
            onClick = onEndCall,
            modifier = Modifier.size(56.dp)
        ) {
            Icon(
                imageVector = Icons.Default.CallEnd,
                contentDescription = "End Call",
                tint = Color.Red
            )
        }

        IconButton(
            onClick = {
                onSpeakerToggle.invoke(isSpeakerOn.value)
                isSpeakerOn.value = !isSpeakerOn.value
            },
            modifier = Modifier.size(56.dp)
        ) {
            Icon(
                imageVector = if (isSpeakerOn.value) Icons.AutoMirrored.Filled.VolumeUp else Icons.AutoMirrored.Filled.VolumeOff,
                contentDescription = if (isSpeakerOn
                        .value
                ) "Speaker On" else "Speaker Off",
                tint = Color.White
            )
        }
    }
}

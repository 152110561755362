package tta.destinigo.talktoastro.feature_landing_page.data.domain

import tta.destinigo.talktoastro.core.remote.Resources
import tta.destinigo.talktoastro.feature_landing_page.data.LandingPageApiService
import tta.destinigo.talktoastro.feature_landing_page.data.request.CourseDetailsSubmitForm
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow

class LandingPageRepo(val api: LandingPageApiService) {
    fun submitCourseDetails(request: CourseDetailsSubmitForm):Flow<Resources<Boolean>>{
        return flow {
            emit(Resources.Loading())
            val response = api.postCourseDetailsRemote(request)
            emit(response)
            emit(Resources.Loading(false))
        }
    }
}
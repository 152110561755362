package tta.destinigo.talktoastro.feature_call_consultation.di

import tta.destinigo.talktoastro.feature_call_consultation.data.api.CallConsultationApi
import tta.destinigo.talktoastro.feature_call_consultation.domain.CallConsultationInfoRepo
import tta.destinigo.talktoastro.feature_call_consultation.domain.VoIPCallRepo
import org.koin.dsl.module

val callConsultationModule = module {
    factory { CallConsultationApi() }
    factory { CallConsultationInfoRepo(get()) }
    factory { VoIPCallRepo(get()) }

//    single { CallConsultationApi() }
//    single { CallConsultationInfoRepo(get()) }
//    single { VoIPCallRepo(get()) }
}
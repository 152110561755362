package tta.destinigo.talktoastro.feature_call_consultation.persentation.voip_call_page


import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.AlertDialog
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import tta.destinigo.talktoastro.core.persentation.showToast
import tta.destinigo.talktoastro.core.remote.Resources
import tta.destinigo.talktoastro.core.theme.Scale
import tta.destinigo.talktoastro.feature_call_consultation.persentation.voip_end_page.ConsultationEndedPage

@Composable
fun VoIPCallConsultationScreen(
    componentContext: VoipCallComponent
) {

    val callEndState by componentContext.callEndedState.collectAsState()
    val state by componentContext.state.collectAsState()

    when(val currentState = state){
        is Resources.Error -> {
            currentState.msg?.let { showToast(it) }
        }
        is Resources.Loading -> {
            LoadingPage(modifier = Modifier.fillMaxSize())
        }
        is Resources.Success -> {
            CallConsultationPage(component = componentContext)
        }
    }
//    if (state is Resources.Loading) {
//
//    }
//
//    if (state is Resources.Success) {
//
//    }

    if (callEndState) {
        AlertDialog(
            containerColor = Color.White,
            onDismissRequest = {
                componentContext.onClosePage()
            },
            text = {
                ConsultationEndedPage(
                    expertInfo = componentContext.expertDetail,
                    duration = "",
                    onRatingSelected = {
                        componentContext.onClosePage()
                    },
                    onDoneClicked = {
                        componentContext.onClosePage()
                    }
                )
            },
            shape = RoundedCornerShape(Scale.CORNER_SMALL),
            confirmButton = {},
            dismissButton = {
//                componentContext.onClosePage()
            }
        )

    }

}

package tta.destinigo.talktoastro.feature_expert_consultation.presentation.expert_search_page

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import tta.destinigo.talktoastro.core.persentation.SimpleDockedSearchBar

@Composable
fun DisplaySearchExpertList(component: SearchScreenComponent){
    //SimpleDockedSearchBar(onBackClick = {})
    Column(modifier = Modifier.fillMaxSize()) {
        SimpleDockedSearchBar(onBackClick = {component.event(SearchScreenEvent.OnBackKeyPress)},"5",component)
    }
}
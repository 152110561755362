package tta.destinigo.talktoastro.core.persentation


import com.russhwolf.settings.Settings
import com.russhwolf.settings.StorageSettings
import io.ktor.client.call.body
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.client.request.url
import io.ktor.http.ContentType
import io.ktor.http.contentType
import io.ktor.util.InternalAPI
import kotlinx.browser.localStorage
import kotlinx.browser.sessionStorage
import kotlinx.browser.window
import kotlinx.serialization.json.Json
import tta.destinigo.talktoastro.core.remote.KtorApi
import tta.destinigo.talktoastro.feature_chat_consultation.data.response.ChatMessage
import tta.destinigo.talktoastro.feature_chat_consultation_new.data.response.save_chat.SaveChatConversationRequest

actual fun getDeviceType(): DeviceType {

    val screenWidthPx = window.innerWidth

    return when {
        screenWidthPx <= 1280 -> DeviceType.WEB_HD // WEB_HD
        screenWidthPx <= 1920 -> DeviceType.WEB_FULL_HD // WEB_FULL_HD
        screenWidthPx <= 2560 -> DeviceType.WEB_2K // WEB_2K
        screenWidthPx <= 3840 -> DeviceType.WEB_4K // WEB_4K
        else -> DeviceType.WEB_UD  // WEB_UD
    }
}

actual fun showToast(message: String) {
    window.alert(message)
}

actual val settings: Settings
    get() = StorageSettings()

actual fun openPlayStore(url: String) {
    window.open(url, "_blank")
}

actual fun clearCaptchaData() {
}

actual fun clearSessionOrCache() {
    // Clear localStorage
    js("localStorage.clear()")

    // Clear sessionStorage
    js("sessionStorage.clear()")

    // Clear cookies (if session or cache is stored in cookies)
    js("document.cookie.split(';').forEach(function(c) { document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/'); });")
}

actual fun refreshPage() {
    window.document.cookie = ""
    window.sessionStorage.clear()
    window.localStorage.clear()
    window.location.href = window.location.href + "?refresh=" + js("Date.now()")
}

actual fun clearData() {
    console.log("Attempting to clear data...")
    if (js("typeof localStorage !== 'undefined'") as Boolean) {
        console.log("Clearing localStorage...")
        localStorage.clear()
    } else {
        console.log("localStorage is not available!")
    }

    if (js("typeof sessionStorage !== 'undefined'") as Boolean) {
        console.log("Clearing sessionStorage...")
        sessionStorage.clear()
    } else {
        console.log("sessionStorage is not available!")
    }
}

actual abstract class BasePagingSource<Value : Any> actual constructor() {
    actual abstract suspend fun fetchData(
        page: Int,
        limit: Int
    ): PaginationItems<Value>

    suspend fun load(page: Int, limit: Int): PaginationItems<Value> {
        return try {
            val response = fetchData(page, limit)
            response
        } catch (e: Exception) {
            throw e
        }
    }
}

// Assuming this function is outside the KtorApi class
actual suspend fun saveChatMessage(
    url: String,
    chatId: String,
    list: List<ChatMessage>
): String {
    // Create an instance of KtorApi
    val ktorApi = object : KtorApi() {}

    // Create the request object with the messages list and chatId
    val request = SaveChatConversationRequest(msg = list.toTypedArray(), chatId)

    // Debug log to check the serialized JSON
    val jsonBody = Json.encodeToString(SaveChatConversationRequest.serializer(), request)
    console.log("Serialized Request Body: $jsonBody")

    return try {
        // Perform POST request with JSON body using the KtorApi instance's client
        val response: String = ktorApi.client.post {
            url("${KtorApi.BASE_URL}$url") // Construct the full URL using BASE_URL
            contentType(ContentType.Application.Json)
            setBody(request)
        }.body()

        response
    } catch (e: Exception) {
        console.log("API call failed: ${e.message}")
        "Error: ${e.message}" // Return error message if something goes wrong
    }
}



@OptIn(InternalAPI::class)
actual suspend fun saveChatMessage(
    url: String,
    data: SaveChatConversationRequest
): String {
    TODO()
//    val client = HttpClient(Js) {
//        install(HttpTimeout) {
//            requestTimeoutMillis = 3000
//        }
//    }
//
//    // Convert list of ChatMsgData to JSON using kotlinx.serialization
//    val jsonData = Json.encodeToString(data.msg)
//
//    return try {
//        // Perform POST request with JSON body
//        val response: String = client.post(url) {
//            contentType(ContentType.Application.Json)
//            parameter("chatId", chatId) // Add chatId as a query parameter
//            body = SaveChatConversationRequest(chatId = data.chatId, msg = jsonData) // Send the list as the body in JSON format
//        }.toString()
//        response // Return confirmation from the server
//    } catch (e: Exception) {
//        console.log("API call failed: ${e.message}")
//        "Error: ${e.message}" // Return error message if something goes wrong
//    } finally {
//        client.close()
//    }
}

// jsMain
actual class UnloadHandler {
    actual fun setupBeforeUnload(endChat: () -> Unit) {
        // Listen for when the tab or browser window is about to close (before unload)
        window.addEventListener("beforeunload", { event ->
            // Call the endChat function when the event is triggered
            endChat()

            // Show a confirmation dialog before closing
            val message = "Are you sure you want to leave this page? Your progress may not be saved."

            // Set the return value of the event, which triggers the browser's confirmation dialog
            event.asDynamic().returnValue = message // This works in most modern browsers

            // Optionally log the event to console for debugging
            console.log("beforeunload event triggered", event)

            // Return the message to show the confirmation alert
            message
        })

        // Listen for when the page is actually unloading (after confirmation)
        window.addEventListener("unload", { event ->
            // This event is triggered after the user confirms closing the tab/browser window
            console.log("unload event triggered", event)

            // Perform any cleanup or saving here if necessary
            // Example: Call an API to save the user's progress or data
            // myApiService.saveUserData()
        })
    }
}

actual fun setupBeforeUnload(endChat: () -> Unit) {
    // Listen for when the tab or browser window is about to close (before unload)
    window.addEventListener("beforeunload", { event ->
        // Call the endChat function when the event is triggered
        endChat()

        // Show a confirmation dialog before closing
        val message = "Are you sure you want to leave this page? Your progress may not be saved."

        // Set the return value of the event, which triggers the browser's confirmation dialog
        event.asDynamic().returnValue = message // This works in most modern browsers

        // Optionally log the event to console for debugging
        console.log("beforeunload event triggered", event)

        // Return the message to show the confirmation alert
        message
    })

    // Listen for when the page is actually unloading (after confirmation)
    window.addEventListener("unload", { event ->
        // This event is triggered after the user confirms closing the tab/browser window
        console.log("unload event triggered", event)

        // Perform any cleanup or saving here if necessary
        // Example: Call an API to save the user's progress or data
        // myApiService.saveUserData()
    })
}
package tta.destinigo.talktoastro.feature_winyway_wallet.persentation.recharge_checkout

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material3.Button
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.Card
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.OutlinedTextField
import androidx.compose.material3.Text
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.ArrowBack
import androidx.compose.material3.CardDefaults
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.ColorFilter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import tta.destinigo.talktoastro.core.theme.Scale
import tta.destinigo.talktoastro.core.theme.background_color
import tta.destinigo.talktoastro.core.theme.green_success_color_light
import tta.destinigo.talktoastro.core.theme.md_them_primary
import tta.destinigo.talktoastro.feature_winyway_wallet.data.PaymentDetailItem
import tta.destinigo.talktoastro.feature_winyway_wallet.data.RechargePack
import tta.destinigo.talktoastro.feature_winyway_wallet.data.request.CheckCouponCodeRequest
import tta.destinigo.talktoastro.feature_winyway_wallet.data.response.RechargeInfoData
import tta.destinigo.talktoastro.feature_winyway_wallet.persentation.all_coupons_page.AllCouponComponent
import tta.destinigo.talktoastro.feature_winyway_wallet.persentation.recharge_checkout.widget.OrderSummary
import tta.destinigo.talktoastro.resources.Res
import tta.destinigo.talktoastro.resources.baseline_check_24
import tta.destinigo.talktoastro.resources.baseline_local_offer_24
import org.jetbrains.compose.resources.painterResource
import tta.destinigo.talktoastro.core.theme.initializeCurrency
import tta.destinigo.talktoastro.core.theme.primaryColor

@Composable
fun RechargeCheckout(
    componentContext: RechargeCheckoutComponent,
    componentContext1: AllCouponComponent
) {
    val rechargeState by componentContext.state.collectAsState()

    val selectedPack =  componentContext.rechargeData

    Column(
        horizontalAlignment = Alignment.CenterHorizontally,
        modifier = Modifier.background(background_color).fillMaxSize().fillMaxWidth()
    ) {
        ToolBar("Checkout", Icons.AutoMirrored.Filled.ArrowBack, onIconClick = {
            componentContext.onBackPress()
        })

        rechargeState?.let {
            if(componentContext.rechargeData?.cupon != null){
                componentContext.checkCouponCode(CheckCouponCodeRequest(couponId = componentContext.rechargeData!!.cupon!!.couponCode.toString(), txnId = it.transationDetails.id.toString()))
            }
            OrderSummary(
                component = componentContext,
                selectedAmount = selectedPack?.amount ?: 0,
                rechargeInfoData = it,
                onPaymentClicked = {
                    componentContext.proceedToPayment(
                        orderId = it.transationDetails.customOrderId.toString(),
                        txnId = it.transationDetails.id.toString(),
                        coinUsed = it.referbalance.usedCoin?.toIntOrNull() ?: 0,
                        cashPayment = it.referbalance.cashAmount!!
                    )
                },
                context = componentContext1
            )
        }
    }
}


@Composable
fun ToolBar(
    title: String, icon: ImageVector, onIconClick: () -> Unit
) {
    Row(
        modifier = Modifier.fillMaxWidth().padding(vertical = 10.dp).padding(horizontal = 16.dp),
        horizontalArrangement = Arrangement.SpaceBetween,
        verticalAlignment = Alignment.CenterVertically
    ) {
        Icon(imageVector = icon,
            contentDescription = null,
            tint = Color.Black,
            modifier = Modifier.padding(start = 4.dp).clickable { onIconClick() })
        Text(
            text = title,
            modifier = Modifier.weight(1f).padding(start = 16.dp),
            color = Color.Black,
            fontSize = 18.sp,
            maxLines = 1,
//            overflow = TextOverflow.Ellipsis,
            fontWeight = FontWeight.Bold
        )
    }
}

@Composable
fun RechargePackOneItem(rechargeData: RechargePack) {
    Card(
        modifier = Modifier.height(120.dp).fillMaxWidth().clip(RoundedCornerShape(8.dp))
            .clickable {}.padding(16.dp),
        elevation = CardDefaults.elevatedCardElevation(Scale.ELEVATION_SMALL)
    ) {
        Column(
            modifier = Modifier.fillMaxSize(),
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.Center
        ) {
            rechargeData.description.takeIf { it.isNotEmpty() }?.let { description ->
                Box(
                    modifier = Modifier.fillMaxWidth().background(Color(0xFFEF5350)),
                    contentAlignment = Alignment.Center
                ) {
                    Text(
                        text = description,
                        fontSize = 13.sp,
                        fontWeight = FontWeight.Bold,
                        color = Color.White,
                        textAlign = TextAlign.Center,
                        modifier = Modifier.padding(vertical = 4.dp, horizontal = 10.dp)
                    )
                }
            }

            Row(
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.Center, // Center content horizontally within the Row
                modifier = Modifier.fillMaxWidth().padding(16.dp),
            ) {
                Text(
                    text = initializeCurrency(),
                    fontWeight = FontWeight.Bold,
                    fontSize = 20.sp,
                    modifier = Modifier.padding(end = 2.dp)
                )
                Text(
                    text = rechargeData.amount.toString(),
                    fontWeight = FontWeight.Bold,
                    fontSize = 20.sp,
                    color = Color.Black
                )
            }
        }
    }
}


@Composable
fun CouponScreen() {
    Column(
        modifier = Modifier.fillMaxWidth().background(background_color).padding(horizontal = 16.dp)
    ) {
        var visible by remember { mutableStateOf(false) } // State variable to track visibility of Applied Coupon
        Row(
            modifier = Modifier.fillMaxWidth(),
            horizontalArrangement = Arrangement.SpaceBetween,
            verticalAlignment = Alignment.CenterVertically
        ) {
            Text(
                text = "Have you coupon ?", color = Color.Black
            )

            Text(text = "All Coupons",
                color = primaryColor,
                fontWeight = FontWeight.Bold,
                modifier = Modifier.clickable {
                })
        }

        var couponText by remember { mutableStateOf("") }

        Row(
            modifier = Modifier.fillMaxWidth(),
            horizontalArrangement = Arrangement.SpaceBetween,
            verticalAlignment = Alignment.CenterVertically
        ) {
            Box(
                modifier = Modifier.weight(3f)
            ) {
                OutlinedTextField(
                    value = couponText,
                    onValueChange = { couponText = it },
                    modifier = Modifier.fillMaxWidth().padding(vertical = 12.dp),
                    label = { Text("Add Coupon") },
                    leadingIcon = {
                        Icon(
                            painter = painterResource(Res.drawable.baseline_local_offer_24),
                            contentDescription = "Coupon Icon"
                        )
                    },
                    keyboardOptions = KeyboardOptions.Default.copy(
                        // Define keyboard options if needed
                    )
                )
            }

            Button(
                onClick = { },
                modifier = Modifier.height(42.dp).padding(start = 8.dp),
                shape = RoundedCornerShape(5.dp),
                colors = ButtonDefaults.buttonColors(primaryColor)
            ) {
                Text(text = "Apply", color = Color.White)
            }
        }

        Spacer(modifier = Modifier.height(16.dp))

        // Applied Coupon visibility
        if (visible) {
            Box(
                modifier = Modifier.fillMaxWidth().background(green_success_color_light)
                    .padding(5.dp)
            ) {
                Column(
                    modifier = Modifier.fillMaxWidth()
                ) {
                    Row(
                        modifier = Modifier.fillMaxWidth().clip(MaterialTheme.shapes.medium),
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        Text(
                            text = "NEW100 - Applied",
                            color = Color.Black,
                            modifier = Modifier.weight(1f)
                        )

                        Text(
                            text = "Remove",
                            color = Color.Black,
                            modifier = Modifier.clickable {
                                // Handle remove coupon logic
                                visible = false // Set visibility to false when clicked
                            },
                        )
                    }

                    Row(
                        modifier = Modifier.align(Alignment.Start)
                    ) {
                        Image(
                            painterResource(Res.drawable.baseline_check_24),
                            contentDescription = "Cashback Image",
                            colorFilter = ColorFilter.tint(Color.Gray)
                        )

                        Text(
                            text = "You will get 300 cashback after recharge done.",
                            color = Color.Gray,
                            modifier = Modifier,
                            maxLines = 1,
                            fontSize = 14.sp,
                        )
                    }
                }
            }
        }
    }
}


@Composable
fun PaymentDetailsScreen(
    rechargeInfoData: RechargeInfoData, onPaymentClicked: () -> Unit = {}
) {
    Column(
        modifier = Modifier.fillMaxSize().padding(16.dp)
    ) {
        Text(text = "Payment Details:")
        // Example :
        // Amount
        // Service Tax (18% GST)
        // Coin Applied Discount
        // Total amount to be paid
        // Cashback Value
        rechargeInfoData.referbalance.let {
            val paymentDetails = listOf(
                PaymentDetailItem(label = "Amount", value = "\u20B9 ${it.amount}"),
                PaymentDetailItem(
                    label = "Service Tax (18% GST)", value = it.gstPercentageAmount.toString()
                ),
                PaymentDetailItem(
                    label = "Coin Applied Discount",
                    value = "- \u20B9 ${it.coinPercentageValue ?: 0}"
                ),
                PaymentDetailItem(
                    label = "Total amount to be paid", value = it.cashAmount.toString()
                ),
                PaymentDetailItem(
                    label = "Cashback Value",
                    value = (rechargeInfoData.transationDetails.cashbackValue ?: 0).toString()
                )
            )
            PaymentDetails(paymentDetails = paymentDetails)
        }

        Spacer(modifier = Modifier.weight(1f))
        Button(
            onClick = {
                onPaymentClicked.invoke()
            },
            modifier = Modifier.fillMaxWidth().height(55.dp),
            colors = ButtonDefaults.buttonColors(primaryColor),
            shape = RoundedCornerShape(5.dp),
        ) {
            Text(text = "Proceed to Payment")
        }
    }
}


@Composable
fun PaymentDetails(paymentDetails: List<PaymentDetailItem>) {
    Column(
        modifier = Modifier.fillMaxWidth()
    ) {
        paymentDetails.forEach { detail ->
            PaymentDetailItems(
                label = detail.label, value = detail.value
            )
        }
    }
}

@Composable
fun PaymentDetailItems(label: String, value: String) {
    Row(
        modifier = Modifier.fillMaxWidth().padding(vertical = 4.dp)
    ) {
        Text(
            text = label, fontSize = 14.sp, modifier = Modifier.weight(1f)
        )
        Text(
            fontSize = 14.sp, text = value
        )
    }
}





package tta.destinigo.talktoastro.feature_expert_consultation.presentation.expert_detail_page.widget

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Language
import androidx.compose.material3.Icon
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import tta.destinigo.talktoastro.core.persentation.CallConsultationButton
import tta.destinigo.talktoastro.core.persentation.ChatConsultationButton
import tta.destinigo.talktoastro.core.persentation.ExpertPriceRow
import tta.destinigo.talktoastro.core.persentation.ExpertProfileIcon
import tta.destinigo.talktoastro.core.theme.primaryColor
import tta.destinigo.talktoastro.feature_call_consultation.persentation.call_starter.CallHostPage
import tta.destinigo.talktoastro.feature_common.data.request.RequestConsultant
import tta.destinigo.talktoastro.feature_expert_consultation.presentation.expert_detail_page.ExpertDetailsPageComponent
import tta.destinigo.talktoastro.feature_expert_consultation.presentation.expert_detail_page.ExpertDetailsPageEvent

@Composable
fun ProfileBox(
    component: ExpertDetailsPageComponent,
    onLoginClick: () -> Unit,
    onChatConsultation: (RequestConsultant) -> Unit,
    onCallConsultation: (RequestConsultant) -> Unit,
    modifier: Modifier = Modifier,
    expertData: RequestConsultant
) {

    var isCallClickSelected by remember { mutableStateOf<RequestConsultant?>(null) }

    Column(modifier = modifier) {
        Box(
            modifier = Modifier.fillMaxWidth(),
            contentAlignment = Alignment.TopStart
        ) {
            Row(
                modifier = Modifier.fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically
            ) {
                // Profile Image

                ExpertProfileIcon(
                    profileImageUrl = expertData.profile_image_url,
                    modifier = Modifier.size(70.dp)
                )

                Spacer(modifier = Modifier.width(16.dp))

                // Consultant Details Column
                Column {
                    // Consultant Name
                    Text(
                        text = expertData.full_name.toString(),
                        style = TextStyle(
                            fontSize = 20.sp,
                            fontWeight = FontWeight.Bold
                        ),
                        color = primaryColor,
                        maxLines = 1
                    )

                    Spacer(modifier = Modifier.height(4.dp))


                    // Consultant Description
                    Text(
                        text = expertData.profile_description!!,
                        style = TextStyle(
                            fontSize = 16.sp,
                            color = Color.DarkGray
                        ),
                        overflow = TextOverflow.Ellipsis,
                        maxLines = 2
                    )

                    Spacer(modifier = Modifier.height(4.dp))
                    Text(
                        text = expertData.skills.toString(),
                        style = TextStyle(
                            fontSize = 14.sp
                        ),
                        maxLines = 1
                    )
                    // Language Text
                    Spacer(modifier = Modifier.height(4.dp))
                    Row(verticalAlignment = Alignment.CenterVertically) {
                        Icon(
                            imageVector = Icons.Default.Language,
                            contentDescription = "World Icon",
                            tint = Color.Black,
                            modifier = Modifier.size(14.dp)
                        )
                        Spacer(modifier = Modifier.width(4.dp))
                        Text(
                            text = expertData.language!!,
                            style = TextStyle(
                                fontSize = 14.sp
                            ),
                            maxLines = 1
                        )
                    }

                    Spacer(modifier = Modifier.height(4.dp))
                }
            }
        }

        // Pricing Text at the bottom of the card
        Column(
            modifier = Modifier
                .padding(vertical = 8.dp)
                .fillMaxWidth(),
            verticalArrangement = Arrangement.Bottom,
            horizontalAlignment = Alignment.Start
        ) {
            Text(
                text = "Best Consultation Price for you: ",
                style = TextStyle(
                    fontSize = 16.sp
                )
            )

            //ExpertCallPrice(expertData, false)
            ExpertPriceRow(
                isFreeCallApplicable = component.isFreeConsultation,
                //isFreeCallApplicable = if(consultant.isFreeCall?.equals(1) == true) true else false,
                callPerMinutePrice = expertData.callPerMinutePrice.toString(),
                expertPrice = expertData.expert_price ?: 0,
                winywayPrice = expertData.winyway_price ?: 0,
                flashPrice = expertData.flashPerMinutePrice,
                horizontalArrangement = Arrangement.Start
            )

            // Action Buttons
            Row(
                modifier = Modifier.fillMaxWidth(),
                horizontalArrangement = Arrangement.SpaceBetween,
                verticalAlignment = Alignment.CenterVertically
            ) {

                CallConsultationButton(
                    modifier = Modifier.weight(1f),
                    chatStatus = expertData.chatButtonStatus,
                    callStatus = expertData.callStatus,
                    onClick = {
                        isCallClickSelected = expertData
                    }
                )

                Spacer(modifier = Modifier.width(6.dp))

                ChatConsultationButton(
                    modifier = Modifier.weight(1f),
                    chatStatus = expertData.chatButtonStatus,
                    callStatus = expertData.callStatus,
                    onClick = {
                        onChatConsultation.invoke(expertData)
                    }
                )
            }

            if (isCallClickSelected != null) {
                CallHostPage(
                    callDetails = isCallClickSelected!!,
                    onLoginClicked = {
                        onLoginClick.invoke()
                    },
                    onDismiss = {
                        isCallClickSelected = null
                    },
                    onStartCallConsultation = { onCallConsultation.invoke(isCallClickSelected!!) },
                    context = component,
                    onNavigateRechargeCheckOut = { rechargeData ->
                        component.event(ExpertDetailsPageEvent.onRechargeRequired(rechargeData))
                    },
                    onVoIpCallStart = {duration, expertInfo ->
                        component.event(
                            ExpertDetailsPageEvent.OnVoIPCallConsultationStart(
                                duration = duration,
                                expertInfo = expertInfo
                            )
                        )
                    },
                    onNavigateCallWaitingPage = {  CommonExpertInfo,onClose ->
                        //onNavigateCallWaitingPage.invoke(CommonExpertInfo,onClose)
                    }
                )
            }

        }
    }
}

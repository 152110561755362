package tta.destinigo.talktoastro.feature_landing_page.persentation.widget

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.*
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import tta.destinigo.talktoastro.resources.Astrology_Png
import tta.destinigo.talktoastro.resources.Res
import org.jetbrains.compose.resources.painterResource

@OptIn(ExperimentalMaterial3WindowSizeClassApi::class)
@Composable
fun AstrologyClassesScreen() {
    val windowSizeClass = calculateWindowSizeClass().widthSizeClass

    Box(modifier = Modifier.fillMaxSize()){
        if (windowSizeClass == WindowWidthSizeClass.Compact) {

            Column(
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(16.dp),
                horizontalAlignment = Alignment.CenterHorizontally,
                verticalArrangement = Arrangement.spacedBy(12.dp) // Adds spacing between elements
            ) {
                // Title
                Text(
                    text = "Online Astrology Classes",
                    fontSize = 32.sp,
                    maxLines = 2,
                    fontWeight = FontWeight.Bold,
                    color = MaterialTheme.colorScheme.primary,
                    modifier = Modifier.padding(bottom = 8.dp).fillMaxWidth() // Adds bottom padding for spacing
                )

                // Introduction text
                Text(
                    text = "Learn Vedic Astrology from Best Astrologers in India",
                    style = MaterialTheme.typography.bodyMedium,
                    fontSize = 20.sp,
                    fontWeight = FontWeight.Bold,
                    color = MaterialTheme.colorScheme.primary,
                    modifier = Modifier.padding(horizontal = 16.dp)
                )

                // Course description text
                Text(
                    text = "If you want to become a Vedic astrologer or learn to read your own kundli.",
                    style = MaterialTheme.typography.bodyMedium,
                    modifier = Modifier.padding(horizontal = 16.dp)
                )

                // Final description
                Text(
                    text = "This Vedic Astrology course offers a captivating introduction to astrology, perfect for those eager to explore how planets influence our lives. The course covers foundational topics, starting with an overview of the twelve zodiac signs and their significance, followed by a deep dive into the role of the nine planets and how their movements can shape various aspects of life. Students will also have the opportunity to create and interpret their own birth chart and chart of others gaining personalized insights into their life’s path. Special focus will be given on predictive mechanism.\n" +
                            "This program doesn’t just stop at learning—it also fosters a supportive community where students can connect with other astrology enthusiasts, stay updated on current trends, and even consider advanced studies in the field. astrology is teached in such a manner that you have proper understanding of reading each chart and how to use astrological knowledge responsibly to offer constructive guidance to others.\n" +
                            "Overall, this Vedic Astrolgoy course  provides not only a deep understanding of astrology but also a pathway to personal growth and self employment. It’s an ideal starting point for those looking to use astrology as a tool for both self-discovery and helping others.".trimIndent(),
                    style = MaterialTheme.typography.bodyMedium.copy(lineHeight = 20.sp),
                    modifier = Modifier.padding(horizontal = 16.dp)
                )

                Spacer(modifier = Modifier.height(16.dp)) // Adds space before the image

//                // Zodiac Wheel Image
                Image(
                    painter = painterResource(Res.drawable.Astrology_Png), // Replace with your drawable
                    contentDescription = "Zodiac Wheel",
                    contentScale = ContentScale.Fit,
                    modifier = Modifier
                        .fillMaxWidth()
                        .size(350.dp) // Adjusted size for better layout
                )
            }
        }else{
            Row(
                modifier = Modifier
                    .fillMaxSize()
                    .padding(16.dp),
                horizontalArrangement = Arrangement.SpaceBetween,
                verticalAlignment = Alignment.CenterVertically
            ) {
                Column(
                    modifier = Modifier.weight(1f),
                    horizontalAlignment = Alignment.Start,
                    verticalArrangement = Arrangement.spacedBy(16.dp)
                ) {

                    // Title
                    Text(
                        text = "Online Astrology Classes",
                        fontSize = 32.sp,
                        fontWeight = FontWeight.Bold,
                        color = MaterialTheme.colorScheme.primary,
                        modifier = Modifier.padding(bottom = 10.dp) // Adds bottom padding for spacing
                    )

                    // Introduction text
                    Text(
                        text = "Learn Vedic Astrology from Best Astrologers in India",
                        style = MaterialTheme.typography.bodyMedium,
                        fontSize = 20.sp,
                        fontWeight = FontWeight.Bold,
                        color = MaterialTheme.colorScheme.primary,
                        modifier = Modifier.padding(horizontal = 16.dp)
                    )

                    // Course description text
                    Text(
                        text = "If you want to become a Vedic astrologer or learn to read your own kundli.",
                        style = MaterialTheme.typography.bodyMedium,
                        modifier = Modifier.padding(horizontal = 16.dp)
                    )

                    // Final description
                    Text(
                        text = "This Vedic Astrology course offers a captivating introduction to astrology, perfect for those eager to explore how planets influence our lives. The course covers foundational topics, starting with an overview of the twelve zodiac signs and their significance, followed by a deep dive into the role of the nine planets and how their movements can shape various aspects of life. Students will also have the opportunity to create and interpret their own birth chart and chart of others gaining personalized insights into their life’s path. Special focus will be given on predictive mechanism.\n" +
                                "This program doesn’t just stop at learning—it also fosters a supportive community where students can connect with other astrology enthusiasts, stay updated on current trends, and even consider advanced studies in the field. astrology is teached in such a manner that you have proper understanding of reading each chart and how to use astrological knowledge responsibly to offer constructive guidance to others.\n" +
                                "Overall, this Vedic Astrolgoy course  provides not only a deep understanding of astrology but also a pathway to personal growth and self employment. It’s an ideal starting point for those looking to use astrology as a tool for both self-discovery and helping others.".trimIndent(),
                        style = MaterialTheme.typography.bodyMedium.copy(lineHeight = 20.sp),
                        modifier = Modifier.padding(horizontal = 16.dp)
                    )
                }

                Spacer(modifier = Modifier.width(16.dp))

                // Zodiac Wheel Image (replace with your image resource)
                Image(
                    painter = painterResource(Res.drawable.Astrology_Png), // Replace with your drawable
                    contentDescription = "Zodiac Wheel",
                    contentScale = ContentScale.Fit,
                    modifier = Modifier
                        .weight(1f)
                        .size(400.dp)
                        .align(Alignment.CenterVertically)
                )
            }
        }
    }
}
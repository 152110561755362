package tta.destinigo.talktoastro.core.theme

import androidx.compose.ui.graphics.Color

val primaryColor = Color(0xFFF57C00)
val primaryLight = Color(0xFFFFAD42)
val primaryDark = Color(0xFFBB4D00)

// LIGHT
val GreenPrimaryLight = Color(0xff006e26)
val OnGreenLight = Color(0xffffffff)
val GreenContainerLight = Color(0xff6cff82)
val OnGreenContainerLight = Color(0xff002106)

val GreenSecondaryLight = Color(0xff526350)
val OnGreenSecondaryLight = OnGreenLight
val GreenSecondaryContainerLight = Color(0xffd4e8d0)
val OnGreenSecondaryContainerLight = Color(0xff101f10)

val GreenTertiaryLight = Color(0xff39656b)
val OnGreenTertiaryLight = OnGreenLight
val GreenTertiaryContainerLight = Color(0xffbcebf2)
val OnGreenTertiaryContainerLight = Color(0xff001f23)

val ErrorLight = Color(0xffba1a1a)
val OnErrorLight = Color(0xffffffff)
val ErrorContainerLight = Color(0xffffdad6)
val OnErrorContainerLight = Color(0xff410002)

val BackgroundLight = Color(0xfffcfdf7)
val OnBackgroundLight = Color(0xff1a1c19)
val SurfaceLight = BackgroundLight
val OnSurfaceLight = OnBackgroundLight
val SurfaceVariantLight = Color(0xffdee5d9)
val OnSurfaceVariantLight = Color(0xff424940)

val OutlineLight = Color(0xff72796f)

// DARK
val GreenPrimaryDark = Color(0xff00e559)
val OnGreenDark = Color(0xff003910)
val GreenContainerDark = Color(0xff00531b)
val OnGreenContainerDark = Color(0xff6cff82)

val GreenSecondaryDark = Color(0xffb9ccb5)
val OnGreenSecondaryDark = OnGreenDark
val GreenSecondaryContainerDark = Color(0xff3a4b39)
val OnGreenSecondaryContainerDark = Color(0xffd4e8d0)

val GreenTertiaryDark = Color(0xffa1ced5)
val OnGreenTertiaryDark = Color(0xff00363c)
val GreenTertiaryContainerDark = Color(0xff1f4d53)
val OnGreenTertiaryContainerDark = Color(0xffbcebf2)

val ErrorDark = Color(0xffffb4ab)
val OnErrorDark = Color(0xff690005)
val ErrorContainerDark = Color(0xff93000a)
val OnErrorContainerDark = Color(0xffffdad6)

val BackgroundDark = Color(0xff1a1c19)
val OnBackgroundDark = Color(0xffe2e3dd)
val SurfaceDark = BackgroundDark
val OnSurfaceDark = OnBackgroundDark
val SurfaceVariantDark = Color(0xff424940)
val OnSurfaceVariantDark = Color(0xffc2c9bd)

val OutlineDark = Color(0xff72796f)


//here custom color
val md_theme_light_primary = Color(0xFF00629F)
val md_theme_light_onPrimary = Color(0xFFFFFFFF)
val md_theme_light_primaryContainer = Color(0xFFD0E4FF)
val md_theme_light_onPrimaryContainer = Color(0xFF001D34)
val md_theme_light_secondary = Color(0xFF00629F)
val md_theme_light_onSecondary = Color(0xFFFFFFFF)
val md_theme_light_secondaryContainer = Color(0xFFD0E4FF)
val md_theme_light_onSecondaryContainer = Color(0xFF001D34)
val md_theme_light_tertiary = Color(0xFF005FAE)
val md_theme_light_onTertiary = Color(0xFFFFFFFF)
val md_theme_light_tertiaryContainer = Color(0xFFD4E3FF)
val md_theme_light_onTertiaryContainer = Color(0xFF001C3A)
val md_theme_light_error = Color(0xFFBA1A1A)
val md_theme_light_errorContainer = Color(0xFFFFDAD6)
val md_theme_light_onError = Color(0xFFFFFFFF)
val md_theme_light_onErrorContainer = Color(0xFF410002)
val md_theme_light_background = Color(0xFFFDFBFF)
val md_theme_light_onBackground = Color(0xFF001B3D)
val md_theme_light_surface = Color(0xFFFDFBFF)
val md_theme_light_onSurface = Color(0xFF001B3D)
val md_theme_light_surfaceVariant = Color(0xFFDEE3EB)
val md_theme_light_onSurfaceVariant = Color(0xFF42474E)
val md_theme_light_outline = Color(0xFF73777F)
val md_theme_light_inverseOnSurface = Color(0xFFECF0FF)
val md_theme_light_inverseSurface = Color(0xFF003062)
val md_theme_light_inversePrimary = Color(0xFF9BCBFF)
val md_theme_light_shadow = Color(0xFF000000)
val md_theme_light_surfaceTint = Color(0xFF00629F)
val md_theme_light_outlineVariant = Color(0xFFC2C7CF)
val md_theme_light_scrim = Color(0xFF000000)

val md_theme_dark_primary = Color(0xFF9BCBFF)
val md_theme_dark_onPrimary = Color(0xFF003256)
val md_theme_dark_primaryContainer = Color(0xFF004A7A)
val md_theme_dark_onPrimaryContainer = Color(0xFFD0E4FF)
val md_theme_dark_secondary = Color(0xFF9BCBFF)
val md_theme_dark_onSecondary = Color(0xFF003256)
val md_theme_dark_secondaryContainer = Color(0xFF004A7A)
val md_theme_dark_onSecondaryContainer = Color(0xFFD0E4FF)
val md_theme_dark_tertiary = Color(0xFFA5C8FF)
val md_theme_dark_onTertiary = Color(0xFF00315E)
val md_theme_dark_tertiaryContainer = Color(0xFF004785)
val md_theme_dark_onTertiaryContainer = Color(0xFFD4E3FF)
val md_theme_dark_error = Color(0xFFFFB4AB)
val md_theme_dark_errorContainer = Color(0xFF93000A)
val md_theme_dark_onError = Color(0xFF690005)
val md_theme_dark_onErrorContainer = Color(0xFFFFDAD6)
val md_theme_dark_background = Color(0xFF001B3D)
val md_theme_dark_onBackground = Color(0xFFD6E3FF)
val md_theme_dark_surface = Color(0xFF001B3D)
val md_theme_dark_onSurface = Color(0xFFD6E3FF)
val md_theme_dark_surfaceVariant = Color(0xFF42474E)
val md_theme_dark_onSurfaceVariant = Color(0xFFC2C7CF)
val md_theme_dark_outline = Color(0xFF8C9199)
val md_theme_dark_inverseOnSurface = Color(0xFF001B3D)
val md_theme_dark_inverseSurface = Color(0xFFD6E3FF)
val md_theme_dark_inversePrimary = Color(0xFF00629F)
val md_theme_dark_shadow = Color(0xFF000000)
val md_theme_dark_surfaceTint = Color(0xFF9BCBFF)
val md_theme_dark_outlineVariant = Color(0xFF42474E)
val md_theme_dark_scrim = Color(0xFF000000)


val md_them_primary = Color(0xFFF57C00)

val md_them_grey_700 = Color(0xFF616161)

val user_profile_image_tint = Color(0xFFEEEEEE)
val error_color = Color(0xFFff002e)
val  color_FFC0C0 = Color(0xFFFFC0C0)


val wifi_call_coming_soon = Color(0xFFEF5350)

val primaryLight50 = Color(0xFFE3F2FD)
val background_color = Color(0xFFecebf6)

val design_default_color_error = Color(0xFFB00020)


val green_success_color_light = Color(0xFFA5D6A7)


val primaryDark_old = Color(0xFF1069BC)


val primaryLightExtra50 = Color(0xFFEEF6FC)
val profile_image_background_color = Color(0xFFEEEEEE)
//<color name="color_1B98F0">#1B98F0</color>

val call_button_background_color = Color(0xFF28a745)
val chat_button_background_color = Color(0xFF1B98F0)
val white = Color(0xFFffffffff)


//val button_color = Color()


val seed = Color(0xFF1B98F0)
val star_rating_color = Color(0xFFFF9C27)
val call_button_color = Color(0xFF5BB971)
val call_and_chat_button_offline_color = Color(0xFF81C784)
val call_button_busy_color = Color(0xFFFF8A65)
val chat_button_online_color = Color(0xFF2ABFFF)
//val chat_and_call_button_busy_color = Color(0xFFFF8A65)
val chat_and_call_button_busy_color = Color(0xFFEF3636)



// Gray Shades
val grey_800 = Color(0xFF424242)
val grey_700 = Color(0xFF616161)
val color_8C8C8C = Color(0xFF8C8C8C)

package tta.destinigo.talktoastro.feature_call_consultation.data.request

//enum class VoIPCallStatus(status:String) {
////    const val END = "End"
////    const val NO_ANSWER = "no-answer"
////    const val FAILED = "failed"
////    const val BUSY = "busy"
////const val IN_PROGRESS = "in-progress"
//    END("End"),NO_ANSWER("no-answer"),FAILED("failed"),BUSY("busy"),IN_PROGRESS("in-progress")
//}

enum class VoIPCallStatus(val status: String) {
    END("End"),
    NO_ANSWER("no-answer"),
    FAILED("failed"),
    BUSY("busy"),
    IN_PROGRESS("in-progress")
}
package tta.destinigo.talktoastro.feature_landing_page.data

import co.touchlab.kermit.Logger
import tta.destinigo.talktoastro.core.remote.KtorApi
import tta.destinigo.talktoastro.core.remote.Resources
import tta.destinigo.talktoastro.feature_landing_page.data.request.CourseDetailsSubmitForm
import io.ktor.client.call.body
import io.ktor.client.plugins.ClientRequestException
import io.ktor.client.plugins.ServerResponseException
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.client.request.url
import io.ktor.http.ContentType
import io.ktor.http.contentType
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.withContext
import tta.destinigo.talktoastro.core.data.CommonResponse

class LandingPageApiService:KtorApi() {

    companion object{
        const val POST_REQUEST_COURSE_DETAILS = "store-quotes-request-data"
    }


    private suspend fun postCourseDetails(request: CourseDetailsSubmitForm):CommonResponse{
        return client.post {
            url(POST_REQUEST_COURSE_DETAILS)
            contentType(ContentType.Application.Json)
            setBody(request)
        }.body()
    }


     suspend fun postCourseDetailsRemote(request: CourseDetailsSubmitForm):Resources<Boolean>{
         return withContext(Dispatchers.Default) {
            try {
                val resources = postCourseDetails(request)
                if(resources.status.not()){
                    Logger.d { "postCourseDetailsRemote ${resources.msg}" }
                    return@withContext (Resources.Error(resources.msg))
                }
                Logger.d { "postCourseDetailsRemote ${resources.msg}" }
                return@withContext(Resources.Success(resources.status))
            }catch (e: ClientRequestException){
                return@withContext(Resources.Error(e.message))
            }catch (e: ServerResponseException){
                return@withContext(Resources.Error(e.message))
            }catch (e: Exception){
                return@withContext(Resources.Error(e.message))
            }
        }
    }

}

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.runtime.Composable
import androidx.compose.runtime.CompositionLocalProvider
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.platform.LocalAccessibilityManager
import androidx.compose.ui.platform.LocalAutofill
import androidx.compose.ui.platform.LocalClipboardManager
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.platform.LocalFocusManager
import androidx.compose.ui.platform.LocalHapticFeedback
import androidx.compose.ui.platform.LocalInputModeManager
import androidx.compose.ui.platform.LocalInspectionMode
import androidx.compose.ui.platform.LocalLayoutDirection
import androidx.compose.ui.platform.LocalSoftwareKeyboardController
import androidx.compose.ui.platform.LocalUriHandler
import androidx.compose.ui.platform.LocalViewConfiguration
import androidx.compose.ui.platform.LocalWindowInfo
import androidx.compose.ui.unit.Density
import androidx.compose.ui.unit.LayoutDirection
import androidx.compose.ui.window.CanvasBasedWindow
import co.touchlab.kermit.Logger
import com.arkivanov.decompose.DefaultComponentContext
import com.arkivanov.decompose.ExperimentalDecomposeApi
import com.arkivanov.decompose.router.stack.webhistory.DefaultWebHistoryController
import com.arkivanov.essenty.lifecycle.LifecycleRegistry
import com.arkivanov.essenty.lifecycle.resume
import com.arkivanov.essenty.lifecycle.stop
import tta.destinigo.talktoastro.core.navigation_decompose.DefaultRootComponent
import tta.destinigo.talktoastro.core.di.initKoin
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.skiko.wasm.onWasmReady
import org.w3c.dom.HTMLScriptElement
import org.w3c.dom.Text
import org.w3c.dom.events.Event
import tta.destinigo.talktoastro.core.navigation_decompose.getPathAndSlug
import kotlin.js.Promise

@OptIn(ExperimentalComposeUiApi::class, ExperimentalDecomposeApi::class)
fun main() {

    Logger.d("JsMain Start Here")

    fun updateMetaTags(title: String, description: String) {
        document.title = title
        val metaDescription = document.querySelector("meta[name='description']")
            ?: document.createElement("meta").apply {
                setAttribute("name", "description")
                document.head?.appendChild(this)
            }
        metaDescription.setAttribute("content", description)
    }

    updateMetaTags(
        title = "TalktoAstro - Online Astrology",
        description = "Unlock your future with a personalized astrology consultation. Talk to astrologer on relationships, career, and life path through birth chart analysis. Book now for clarity and cosmic guidance"
    )


    loadSkiko().then {

        Logger.d("JsMain Start Loading Skkio")


        initKoin()
        val myLifecycleOwner = MyLifecycleOwner()
        val lifecycleRegistry = LifecycleRegistry()


        val root = DefaultRootComponent(
            componentContext = DefaultComponentContext(lifecycle = myLifecycleOwner.lifecycle),
            deepLink = DefaultRootComponent.DeepLink.Web(path = getPathAndSlug(window.location.href)),
            webHistoryController = DefaultWebHistoryController()
        )

        val prefersDarkTheme = window.matchMedia("(prefers-color-scheme: dark)").matches

        onWasmReady {

            lifecycleRegistry.attachToDocument()
            var density by mutableStateOf(Density(1.0f))

            fun updateDensity() {
                val dpr = window.devicePixelRatio.toFloat()
                density = Density(dpr)
            }

            updateDensity()

            window.addEventListener("resize", { updateDensity() })

            CanvasBasedWindow(title = "TalktoAstro - Online Astrology") {

                Logger.d("JsMain Canvas Based Window Start here")


                CompositionLocalProvider(
                    LocalDensity provides density,
                    LocalLayoutDirection provides LayoutDirection.Ltr,
                    LocalUriHandler provides LocalUriHandler.current,
                    LocalViewConfiguration provides LocalViewConfiguration.current,
                    LocalInputModeManager provides LocalInputModeManager.current,
                    LocalClipboardManager provides LocalClipboardManager.current,
                    LocalAutofill provides LocalAutofill.current,
                    LocalFocusManager provides LocalFocusManager.current,
                    LocalAccessibilityManager provides LocalAccessibilityManager.current,
                    LocalHapticFeedback provides LocalHapticFeedback.current,
                    LocalWindowInfo provides LocalWindowInfo.current,
                    LocalSoftwareKeyboardController provides LocalSoftwareKeyboardController.current,
                    LocalInspectionMode provides LocalInspectionMode.current,
                ) {
                    App(
                        darkTheme = prefersDarkTheme,
                        dynamicColor = true,
                        rootComponent = root
                    )
                }
            }
        }
    }.catch { error ->
        console.error("Failed to load Skiko: $error")
        // Optional: handle error gracefully
    }
}

@Composable
private fun LoadingDialog(){
    Box(
        modifier = Modifier
            .fillMaxSize()
            .background(color = Color.White),
        contentAlignment = Alignment.Center
    ) {
        CircularProgressIndicator()
        Text("Loading...")
    }
}


private fun LifecycleRegistry.attachToDocument() {
    val visibilityState = document.asDynamic().visibilityState as String
    fun onVisibilityChanged() {
        if (visibilityState == "visible") {
            resume()
        } else {
            stop()
        }
    }

    onVisibilityChanged()
        document.addEventListener("visibilitychange", { _: Event ->
        onVisibilityChanged()
    })

}

fun loadSkiko(): Promise<Unit> {
    return Promise { resolve, reject ->
        val script = document.createElement("script") as HTMLScriptElement
        script.src = "skiko.js"
        script.onload = { resolve(Unit) }
        script.onerror = { _: dynamic, _: String, _: Int, _: Int, _: Any? -> reject(Exception("Failed to load skiko.js")) }
        document.head?.appendChild(script)
    }
}







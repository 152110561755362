package tta.destinigo.talktoastro.feature_custom_order.data.response

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Pagination(
    @SerialName("first_page_url") val firstPageUrl: String, // https://prod.winyway.com/api/v1/custom-order/get-list?page=1
    @SerialName("from") val from: Int, // 1
    @SerialName("last_page") val lastPage: Int, // 2
    @SerialName("last_page_url") val lastPageUrl: String, // https://prod.winyway.com/api/v1/custom-order/get-list?page=2
    @SerialName("next_page_url") val nextPageUrl: String, // https://prod.winyway.com/api/v1/custom-order/get-list?page=2
    @SerialName("path") val path: String, // https://prod.winyway.com/api/v1/custom-order/get-list
    @SerialName("per_page") val perPage: Int, // 15
    @SerialName("prev_page_url") val prevPageUrl: String?, // null
    @SerialName("to") val to: Int, // 15
    @SerialName("total") val total: Int // 17
)

package tta.destinigo.talktoastro.feature_custom_order.persentation

import androidx.compose.foundation.ExperimentalFoundationApi
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.grid.GridCells
import androidx.compose.foundation.lazy.grid.LazyVerticalGrid
import androidx.compose.foundation.lazy.grid.items
import androidx.compose.foundation.lazy.grid.rememberLazyGridState
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.lazy.rememberLazyListState
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.currentComposer
import androidx.compose.runtime.getValue
import androidx.compose.runtime.snapshotFlow
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import co.touchlab.kermit.Logger
import kotlinx.coroutines.flow.distinctUntilChanged
import tta.destinigo.talktoastro.core.persentation.CommonTopAppBar
import tta.destinigo.talktoastro.core.theme.Scale.WINDOW_WIDTH_SIZE_COMPACT
import tta.destinigo.talktoastro.core.theme.Scale.WINDOW_WIDTH_SIZE_ELSE
import tta.destinigo.talktoastro.core.theme.Scale.WINDOW_WIDTH_SIZE_EXPEND
import tta.destinigo.talktoastro.core.theme.Scale.WINDOW_WIDTH_SIZE_MEDIUM
import tta.destinigo.talktoastro.core.theme.background_color
import tta.destinigo.talktoastro.feature_custom_order.CustomOrderComponent
import tta.destinigo.talktoastro.feature_custom_order.CustomOrderListState

//@Composable
//fun CustomHostPage(component: CustomOrderComponent){
//    val state by component.state.collectAsState()
//
//    when(val currentState = state){
//        is CustomOrderListState.CustomOrderListSuccess -> {
//            Logger.d { "CustomHostPage success page ${currentState.data}" }
//            Logger.d { "CustomHostPage success page ${currentState.data.size}" }
//
//            LazyColumn {
//                item {
//                    currentState.data.forEach {
//                        it.categoryName
//                        Text(text = it.categoryName, textDecoration = null)
//
//                    }
//                    currentState.data.forEach {
//                        it.items.forEach { it1 ->
//                            CustomOrderCard(true,it1)
//                        }
//                    }
//
//
//                }
//            }
//        }
//        is CustomOrderListState.Error -> {
//            Logger.d { currentState.msg }
//        }
//        is CustomOrderListState.Loading -> {
//            Logger.d { "${currentState.isLoading}" }
//        }
//    }
//
//}

//@OptIn(ExperimentalMaterial3Api::class, ExperimentalMaterial3WindowSizeClassApi::class)
//@Composable
//fun CustomHostPage(component: CustomOrderComponent) {
//    val state by component.state.collectAsState()
//    val listState = rememberLazyListState()
//    val windowSizeClass = calculateWindowSizeClass().widthSizeClass
//    val widthModifier = when (windowSizeClass) {
//        WindowWidthSizeClass.Compact -> WINDOW_WIDTH_SIZE_COMPACT
//        WindowWidthSizeClass.Medium -> WINDOW_WIDTH_SIZE_MEDIUM
//        WindowWidthSizeClass.Expanded -> WINDOW_WIDTH_SIZE_EXPEND
//        else -> WINDOW_WIDTH_SIZE_ELSE
//    }
//
//
//    LaunchedEffect(listState) {
//        snapshotFlow { listState.layoutInfo.visibleItemsInfo.lastOrNull()?.index }
//            .distinctUntilChanged()
//            .collect { lastVisibleIndex ->
//                if (lastVisibleIndex == listState.layoutInfo.totalItemsCount - 1) {
//                    // Load next page when the last item becomes visible
//                    component.loadNextPage()
//                }
//            }
//    }
//
//    val chatHistoryList by component.state.collectAsState()
//
//
//    LaunchedEffect(listState) {
//        snapshotFlow {
//            listState.layoutInfo.visibleItemsInfo.lastOrNull()?.index
//        }.collect { lastVisibleItemIndex ->
//            // Load more chat history when nearing the end of the list
//            if (lastVisibleItemIndex != null && lastVisibleItemIndex >= (state.firstOrNull()?.items?.size?.minus(
//                    3
//                ) ?: 0)
//            ) {
//                component.loadNextPage()
//            }
//        }
//    }
//
//    Box(
//        modifier = Modifier
//            .fillMaxSize()
//            .background(background_color),
//        contentAlignment = Alignment.Center
//    ) {
//        Column(
//            modifier = widthModifier
//                .fillMaxSize()
//                .padding(16.dp, top = 0.dp)
//        ) {
//            CommonTopAppBar(
//                title = "Chat History",
//                modifier = Modifier,
//                onBackClick = { }
//            )
//            Spacer(modifier = Modifier.height(16.dp))
//            if (chatHistoryList.isNotEmpty() && chatHistoryList.firstOrNull()?.items?.isNotEmpty() == true) {
//                LazyColumn(
//                    state = listState,
//                    modifier = Modifier.fillMaxSize()
//                ) {
//                    items(chatHistoryList.flatMap { it.items.orEmpty() }) { customOrderItem -> // Flatten the list if needed
//                        customOrderItem.let {
//                            CustomOrderCard(true, data = it)
//                        }
//                    }
//                }
//            }
//        }
//    }
//}


@OptIn(ExperimentalMaterial3Api::class, ExperimentalMaterial3WindowSizeClassApi::class, ExperimentalFoundationApi::class)
@Composable
fun CustomHostPage(component: CustomOrderComponent) {
    val state by component.state.collectAsState()
    val gridState = rememberLazyGridState()
    val windowSizeClass = calculateWindowSizeClass().widthSizeClass

    val columns = when (windowSizeClass) {
        WindowWidthSizeClass.Compact -> 1
        WindowWidthSizeClass.Medium -> 2
        WindowWidthSizeClass.Expanded -> 3
        else -> 1
    }

    LaunchedEffect(gridState) {
        snapshotFlow { gridState.layoutInfo.visibleItemsInfo.lastOrNull()?.index }
            .distinctUntilChanged()
            .collect { lastVisibleIndex ->
                if (lastVisibleIndex == gridState.layoutInfo.totalItemsCount - 1) {
                    component.loadNextPage()
                }
            }
    }

    val chatHistoryList by component.state.collectAsState()

    Box(
        modifier = Modifier
            .fillMaxSize()
            .background(Color.LightGray),
        contentAlignment = Alignment.Center
    ) {
        Column(
            modifier = Modifier
                .fillMaxSize()
                .padding(16.dp)
        ) {
            CommonTopAppBar(
                title = "Chat History",
                modifier = Modifier,
                onBackClick = { }
            )
            Spacer(modifier = Modifier.height(16.dp))
            if (chatHistoryList.isNotEmpty() && chatHistoryList.firstOrNull()?.items?.isNotEmpty() == true) {
                var columnsInRow:Int = 0
                chatHistoryList.forEach { chatHistory ->
                    val itemCount = chatHistory.items.size
                    columnsInRow = when {
                        itemCount <= 1 -> 1
                        itemCount == 2 -> 2
                        itemCount >= 3 -> 3
                        else -> 1
                    }
                }
                LazyVerticalGrid(
                    columns = GridCells.Fixed(columnsInRow),
                    state = gridState,
                    modifier = Modifier.fillMaxSize(),
                    contentPadding = PaddingValues(8.dp)
                ) {
                    chatHistoryList.forEach { chatHistory ->
                        // Display the category name once for each `chatHistory`
                        item {
                            Column {
                                chatHistory.categoryName?.let { categoryName ->
                                    Text(text = categoryName, textDecoration = null)
                                }
                                chatHistory.items.forEach { customOrderItem ->
                                    CustomOrderCard(false, data = customOrderItem)
                                }
                            }
                        }
                    }
                }
//                LazyVerticalGrid(
//                    columns = GridCells.Fixed(columns),
//                    state = gridState,
//                    modifier = Modifier.fillMaxSize(),
//                    contentPadding = PaddingValues(8.dp)
//                ) {
////                    items(chatHistoryList.map { it.categoryName .orEmpty() }){
////                        Text(text = it, textDecoration = null)
////                        chatHistoryList.forEach { customOrderItem ->
////                            customOrderItem.
////                        }
////                    }
////                    items(chatHistoryList.flatMap { it.items.orEmpty() }) { customOrderItem ->
////                        customOrderItem.let {
////                            CustomOrderCard(true, data = it)
////                        }
////                    }
//
////                    items(chatHistoryList.flatMap { listOf(it.items.orEmpty().map { item -> item to it.categoryName }) }.flatten()) { (customOrderItem, categoryName) ->
////                        categoryName.let {
////                            Text(text = categoryName, textDecoration = null)
////                        }
////                        customOrderItem?.let {
////                            CustomOrderCard( false, data = it)
////                        }
////                    }
//
//                    chatHistoryList.forEach { chatHistory ->
//                        // Display the category name once for each `chatHistory`
//                        chatHistory.categoryName?.let { categoryName ->
//                            item{
//                            Text(text = categoryName, textDecoration = null)
//                                }
//                        }
//
//                        // Display each item in `items` under the category
//                        items(chatHistory.items.orEmpty()) { customOrderItem ->
//                            CustomOrderCard( false, data = customOrderItem)
//                        }
//                    }
//
//
//                }
            }
        }
    }
}

//@OptIn(ExperimentalMaterial3Api::class, ExperimentalMaterial3WindowSizeClassApi::class, ExperimentalFoundationApi::class)
//@Composable
//fun CustomHostPage(component: CustomOrderComponent) {
//    val state by component.state.collectAsState()
//    val gridState = rememberLazyGridState()
//    val windowSizeClass = calculateWindowSizeClass().widthSizeClass
//
//    // Default to 1 column for Compact screen sizes
//    val columns = when (windowSizeClass) {
//        WindowWidthSizeClass.Compact -> 1
//        WindowWidthSizeClass.Medium -> 2
//        WindowWidthSizeClass.Expanded -> 3
//        else -> 1
//    }
//
//    LaunchedEffect(gridState) {
//        snapshotFlow { gridState.layoutInfo.visibleItemsInfo.lastOrNull()?.index }
//            .distinctUntilChanged()
//            .collect { lastVisibleIndex ->
//                if (lastVisibleIndex == gridState.layoutInfo.totalItemsCount - 1) {
//                    component.loadNextPage()
//                }
//            }
//    }
//
//    val chatHistoryList by component.state.collectAsState()
//
//    Box(
//        modifier = Modifier
//            .fillMaxSize()
//            .background(Color.LightGray),
//        contentAlignment = Alignment.Center
//    ) {
//        Column(
//            modifier = Modifier
//                .fillMaxSize()
//                .padding(16.dp)
//        ) {
//            CommonTopAppBar(
//                title = "Chat History",
//                modifier = Modifier,
//                onBackClick = { }
//            )
//            Spacer(modifier = Modifier.height(16.dp))
//
//            // Use LazyColumn directly for vertical scrolling
//            LazyColumn(
//                modifier = Modifier.fillMaxSize(),
//                contentPadding = PaddingValues(8.dp)
//            ) {
//                chatHistoryList.forEach { chatHistory ->
//                    item {
//                        // Display the category name
//                        chatHistory.categoryName?.let { categoryName ->
//                            Text(
//                                text = categoryName,
//                                modifier = Modifier.padding(vertical = 8.dp),
//                               // style = MaterialTheme.typography.subtitle1
//                            )
//                        }
//                    }
//
//                    // Dynamically determine columns based on item count
//                    item {
//                        val itemCount = chatHistory.items.size
//                        val columnsInRow = when {
//                            itemCount <= 1 -> 1
//                            itemCount == 2 -> 2
//                            itemCount >= 3 -> 3
//                            else -> 1
//                        }
//
//                        LazyVerticalGrid(
//                            columns = GridCells.Fixed(columnsInRow),
//                            modifier = Modifier.fillMaxWidth(),
//                            contentPadding = PaddingValues(vertical = 4.dp)
//                        ) {
//                            items(chatHistory.items.orEmpty()) { customOrderItem ->
//                                CustomOrderCard(false, data = customOrderItem)
//                            }
//                        }
//                    }
//                }
//            }
//        }
//    }
//}


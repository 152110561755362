package tta.destinigo.talktoastro.feature_expert_consultation.data.remote

import tta.destinigo.talktoastro.core.remote.KtorApi
import tta.destinigo.talktoastro.core.remote.Resources
import tta.destinigo.talktoastro.feature_expert_consultation.data.remote.request.ExpertListRequest
import tta.destinigo.talktoastro.feature_expert_consultation.data.remote.request.FavoriteRequest
import tta.destinigo.talktoastro.feature_expert_consultation.data.remote.response.CategoriesResponse
import tta.destinigo.talktoastro.feature_expert_consultation.data.remote.response.CategoryData
import tta.destinigo.talktoastro.feature_expert_consultation.data.remote.response.FetchConsultantResponse
import tta.destinigo.talktoastro.feature_expert_consultation.presentation.expert_list_page.expert_list_state_info.ExpertListState
import tta.destinigo.talktoastro.feature_expert_consultation.presentation.expert_list_page.expert_list_state_info.MarkFeavoriteState
import io.ktor.client.call.body
import io.ktor.client.plugins.ClientRequestException
import io.ktor.client.plugins.ServerResponseException
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.client.request.url
import io.ktor.http.ContentType
import io.ktor.http.contentType
import io.ktor.utils.io.errors.IOException
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow
import tta.destinigo.talktoastro.core.data.CommonResponse

class ExpertApi : KtorApi() {

    companion object {
        const val FETCH_CONSULTANT_LIST = "fetch-consultants"
        const val MARK_FAV = "favourite"
        const val GET_CATEGORY = "categories"
    }

    private suspend fun getExpertList(request: ExpertListRequest): FetchConsultantResponse {
        return client.post {
            url(FETCH_CONSULTANT_LIST)
            contentType(ContentType.Application.Json)
            setBody(request)
        }.body()
    }

    private suspend fun getCategoryApi(): CategoriesResponse {
        return client.post {
            url(GET_CATEGORY)
        }.body()
    }

    private suspend fun markExpertFavorite(id: String) = client.post {
        url(MARK_FAV)
        setBody(FavoriteRequest(consultantId = id))
    }.body<CommonResponse>()

    suspend fun getExpertListRemote(request: ExpertListRequest): Flow<ExpertListState> {
        return flow {
            emit(ExpertListState.Loading)
            try {
                val response = getExpertList(request)

                if (response.status.not()) {
                    emit(ExpertListState.Error(response.message))
                } else {
                    val isFreeApplicable = response.data?.freeCallAvailability == 1
                    emit(
                        ExpertListState.Success(
                            consultants = response.data?.consultants.orEmpty(),
                            isFreeApplicable = isFreeApplicable
                        )
                    )
                }
            } catch (apiError: Exception) {
                emit(ExpertListState.Error(apiError.message))
            } catch (httpException: IOException) {
                emit(ExpertListState.Error(httpException.message))
            } finally {
                // Optionally, you can handle final cleanup or state updates here if needed
            }
        }
    }

    suspend fun getCategoryList(): Flow<Resources<CategoryData>> {
        return flow {
            emit(Resources.Loading(true))
            try {
                val response = getCategoryApi()

                if (!response.status) {
                    emit(Resources.Error(response.message))
                } else {
                    emit(Resources.Success(response.data))
                }
            } catch (apiError: Exception) {
                emit(Resources.Error(apiError.message))
            } catch (httpException: IOException) {
                emit(Resources.Error(httpException.message))
            } finally {
                // Optionally, you can handle final cleanup or state updates here if needed
            }
        }
    }

    suspend fun postMarkFavorite(id: String): Flow<MarkFeavoriteState> {
        return flow {
            emit(MarkFeavoriteState.Loading(true))
            try {
                val response = markExpertFavorite(id)
                if (response.status.not()) {
                    emit(MarkFeavoriteState.Error(response.msg))
                } else {
                    emit(MarkFeavoriteState.Success(response.msg))
                }
            } catch (e: ClientRequestException) {
                emit(MarkFeavoriteState.Error(e.message))
            } catch (e: ServerResponseException) {
                emit(MarkFeavoriteState.Error(e.message))
            } catch (e: Exception) {
                emit(MarkFeavoriteState.Error(e.message))
            }
        }
    }


}

package tta.destinigo.talktoastro.user_feature_authentication.domain

import co.touchlab.kermit.Logger
import tta.destinigo.talktoastro.core.local.TokenProvider
import tta.destinigo.talktoastro.core.local.UserInfoProvider
import tta.destinigo.talktoastro.core.remote.Resources
import tta.destinigo.talktoastro.user_feature_authentication.data.AuthApiService
import tta.destinigo.talktoastro.user_feature_authentication.data.request.RequestOtpRequest
import tta.destinigo.talktoastro.user_feature_authentication.data.request.VerifyOtpRequest
import tta.destinigo.talktoastro.user_feature_authentication.data.response.RequestOtpData
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow

class OtpValidationUseCases(
    private val authApiService: AuthApiService
) {

    suspend fun onOtpValidation(request: VerifyOtpRequest): Flow<Resources<CurrentScreen>> {
        return flow {
            authApiService.validateOtp(request).collect { state ->
                Logger.d("returnedFlow -> $state")
                when (state) {
                    is Resources.Error -> emit(Resources.Error(state.msg ?: "Unexpected Error"))
                    is Resources.Loading -> emit(Resources.Loading(state.isLoading))
                    is Resources.Success -> {
                        var data = CurrentScreen.REGISTER_USER
                        state.data?.token?.let { TokenProvider.setNewToken(it) }
                        state.data?.token?.let { TokenProvider.setRefreshToken(it) }
                        state.data?.user?.let {
                            UserInfoProvider.setFullName(it.fullName)
                            UserInfoProvider.setEmail(it.email ?: "")
                            UserInfoProvider.setPhone(it.mobile)
                            UserInfoProvider.setImage(it.profileImageUrl)
                            UserInfoProvider.setId(it.id.toString())
                            UserInfoProvider.setMobileCode(it.mobileCode)
                            UserInfoProvider.setReferralCode(state.data.referCode ?: "")

                            if (it.redirectTo.equals("dashboard", true)) {
                                data = CurrentScreen.LOGIN_USER
                                UserInfoProvider.setIsLogin(true)
                            } else {
                                data = CurrentScreen.REGISTER_USER
                                UserInfoProvider.setIsLogin(false)
                            }
                        }
                        emit(Resources.Success(data))
                    }
                }
            }
        }
    }

    suspend fun requestOtp(request: RequestOtpRequest): Flow<Resources<RequestOtpData>> {
        return flow {
//            println("RequestOtpRequest: $request")
            authApiService.requestLoginOtp(request).collect {
                emit(it)
            }
        }
    }
}
package tta.destinigo.talktoastro.feature_custom_order.domain

import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow
import tta.destinigo.talktoastro.core.remote.Resources
import tta.destinigo.talktoastro.feature_chat_consultation_new.data.response.chat_duration.ChatDurationData
import tta.destinigo.talktoastro.feature_custom_order.data.api.CustomOrderApi
import tta.destinigo.talktoastro.feature_custom_order.data.request.CustomOrderListRequest
import tta.destinigo.talktoastro.feature_custom_order.data.response.Data

class CustomOrderListRepo( private val api: CustomOrderApi) {

    fun getCustomOrderInfo(request: CustomOrderListRequest): Flow<Resources<Data>>{
        return flow {
            emit(Resources.Loading())
            val response = api.customOrderListBody(request)
            emit(response)
            emit(Resources.Loading(false))
        }
    }
}